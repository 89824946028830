import React from "react";
import connect from "react-redux/es/connect/connect";
import withAlerts from "./withAlerts";
import { options_country, options_platform_category } from "../lib/model";
import debounce from "lodash.debounce";
import Loading from "./Loading";
import ActionButton from "./ActionButton";
import FileSaver from "file-saver";

class Data extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            numDatasets: null,
            numObservations: null,
            platform_category: "",
            platform_country: "",
            title: "",
            isDownloading: false,
            datasetIsDownloading: new Set(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleDownloadDataset = this.handleDownloadDataset.bind(this);
        this.debounceUpdate = debounce(this.fetchData, 500);
    }
    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            this.debounceUpdate();
        });
    }
    makeQueryString() {
        let q = {};
        if (this.state.title) {
            q.title = this.state.title;
        }
        if (this.state.platform_category) {
            q.platform_category = this.state.platform_category;
        }
        if (this.state.platform_country) {
            q.platform_country = this.state.platform_country;
        }
        return new URLSearchParams(q).toString();
    }
    fetchData() {
        this.setState({
            data: [],
            loading: true,
            numDatasets: null,
            numObservations: null
        });
        fetch("/api/data?" + this.makeQueryString(), {
            headers: new Headers({
                "Accept": "application/json",
                "Authorization": this.props.token
            })
        })
        .then(res => res.json())
        .then(res => {
            this.setState({
                data: res.results,
                numDatasets: res.total,
                numObservations: res.results.reduce((sum, x) => sum + parseInt(x.records), 0),
                loading: false
            });
        });
    }
    handleDownload() {
        this.props.showConfirm(() => {
            this.setState({
                isDownloading: true
            });
            fetch("/api/data/download?" + this.makeQueryString(), {
                method: "GET",
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": this.props.token
                })
            }).then(res => {
                if (res.status !== 200) {
                    this.props.addAlert({
                        "type": "warning",
                        "message": "Something has gone wrong"
                    });
                }
                return res.blob();
            }).then(blob => {
                FileSaver.saveAs(blob, "data.zip");
                this.setState({
                    isDownloading: false
                });
            });
        }, "Downloading data can take a few minutes, please keep your browser window open.");
    }
    handleDownloadDataset(id, e) {
        e.stopPropagation();
        this.props.showConfirm(() => {

            let downloading = new Set(this.state.datasetIsDownloading);
            downloading.add(id);
            this.setState({ datasetIsDownloading: downloading });

            fetch("/api/data/download?dataset_id=" + id, {
                method: "GET",
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": this.props.token
                })
            }).then(res => {
                if (res.status !== 200) {
                    this.props.addAlert({
                        "type": "warning",
                        "message": "Something has gone wrong"
                    });
                }
                return res.blob();
            }).then(blob => {
                FileSaver.saveAs(blob, id + ".zip");

                downloading = new Set(this.state.datasetIsDownloading);
                downloading.delete(id);
                this.setState({ datasetIsDownloading: downloading });

            });
        }, "Downloading data can take a few minutes, please keep your browser window open.");
    }
    componentDidMount() {
        // todo: check authenticated instead of token?
        if (this.props.token) {
            this.fetchData();
        } else {
            this.props.history.push("/signin");
        }
    }
    render() {
        return (
            <div>
                <h2>Data search</h2>
                <div className="pagepanel">
                    <form className="mt-3 mb-3">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label>Title</label>
                                <input type="text" value={this.state.title} onChange={this.handleChange} className="form-control" name="title" placeholder="Title"/>
                            </div>
                            <div className="form-group col-md-4">
                                <label>Platform category</label>
                                <select name="platform_category" value={ this.state.platform_category } onChange={this.handleChange} className="form-control" >
                                    <option value="">(select platform category)</option>
                                    {
                                        options_platform_category.map(option => <option key={ option.value } >{ option.label }</option>)
                                    }
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label>Platform country</label>
                                <select name="platform_country" value={ this.state.platform_country } onChange={this.handleChange} className="form-control" >
                                    <option value="">(select platform country)</option>
                                    {
                                        options_country.map(option => <option key={ option.value } >{ option.label }</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </form>

                    { this.state.numDatasets != null &&
                        <div>
                            <p>Found <b>{this.state.numObservations.toLocaleString()}</b> observations from <b>{this.state.numDatasets}</b> datasets.</p>

                            <ActionButton isLoading={this.state.isDownloading} onClick={() => this.handleDownload()}>Download all</ActionButton>

                            <table className="table table-sm table-hover mt-4">
                                <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Observations</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.data.map((dataset, index) =>
                                        <tr key={ index }>
                                            <td>{ dataset.title }</td>
                                            <td>{ parseInt(dataset.records).toLocaleString() }</td>
                                            <td><ActionButton isLoading={this.state.datasetIsDownloading.has(dataset.id)} className="ml-2 text-nowrap" onClick={(e) => this.handleDownloadDataset(dataset.id, e)}>Download</ActionButton></td>
                                        </tr>
                                    )
                                }
                                { !this.state.loading && this.state.data.length === 0 && <tr><td colSpan="2">No datasets found.</td></tr> }
                                </tbody>
                            </table>
                        </div>
                    }

                    { this.state.loading && <Loading/> }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token,
        isAdmin: state.isAdmin
    }
};

export default withAlerts(connect(mapStateToProps)(Data));
