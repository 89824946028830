import React from "react";

export default function FileStatus(props) {
    if (props.file.success) {
        return <span className="badge badge-success">success</span>;
    } else if (props.file.success == null) {
        return <span className="badge badge-light">busy</span>;
    } else {
        return <span className="badge badge-warning">failed</span>;
    }
};