import React, { Component } from "react";

export default class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            header_lines: 1
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleHeaderLinesChange = this.handleHeaderLinesChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }
    handleChange(files) {
        this.setState({
            file: files[0]
        });
    }
    handleHeaderLinesChange(e) {
        this.setState({
            header_lines: e.target.value
        });
    }
    handleUpload() {
        if (this.state.file) {
            this.props.handleUpload(this.state.file, this.state.header_lines);
        } else {
            alert("No file selected");
        }
    }
    render() {
        return (
            <div className="form-row">
                <div className="form-group col-3 pl-1">
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="customFile" accept={ this.props.accept } onChange={ (e) => this.handleChange(e.target.files) }/>
                        <label className="custom-file-label" htmlFor="customFile">
                            { this.state.file && <span>{ this.state.file.name }</span> }
                            { !this.state.file && <span>Choose file</span> }
                        </label>
                    </div>
                </div>
                { this.props.show_header_lines && <div className="form-group pr-1">
                    <select className="form-control" onChange={ this.handleHeaderLinesChange } value={ this.state.header_lines }>
                        <option value="1">1 header lines</option>
                        <option value="2">2 header lines</option>
                        <option value="3">3 header lines</option>
                    </select>
                </div> }
                <div className="form-group pl-0">
                    <button className="btn btn-primary" onClick={ this.handleUpload } disabled={ this.props.isProcessing }>
                        Upload
                        { this.props.isProcessing && <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span> }
                    </button>
                </div>
            </div>
        )
    }
}