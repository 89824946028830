const assert = require("assert");
const defaultMetadata = require("./model").metadata;

const validateEmail = function(value) {
    const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return re.test(value);
};

const validatePhone = function(value) {
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    return re.test(value);
};

const validateDate = function(value) {
    const re = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])(T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?)?$/;
    return re.test(value);
};

const validateNumeric = function(value) {
    const re = /^(-?\d+\.\d+)$|^(-?\d+)$/;
    return re.test(value);
};

const validateExpoCode = function(value) {
    const re = /^[0-9A-Za-z-]{1,15}$/;
    return re.test(value);
};

const validateUrl = function(value) {
    const re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    return re.test(value);
};

const validateDoi = function(value) {
    const re = /^(doi:)?10.\d{4,9}\/[-._;()/:A-Z0-9]+$/i;
    return re.test(value);
};

const addError = function(errors, name, error) {
    if (!(name in errors)) errors[name] = [];
    errors[name].push(error);
};

const isEmpty = function(value) {
    return (value == null || value === "" || (typeof value === "string" && value.startsWith("(select")));
};

/**
 * Validate a single field. This is called while editing a form field.
 */
const validateField = function(name, metadata) {

    if (name.startsWith("custom_")) {
        name = name.replace(/custom_[0-9a-z]*?_/, "custom_");
    }

    assert(defaultMetadata[name]);
    const value = metadata[name];

    const errors = {};

    // required fields

    if (defaultMetadata[name].required) {

        if (defaultMetadata[name].required === true && isEmpty(value)) {

            // required field, not conditional
            addError(errors, name, "Field is required");

        } else if (defaultMetadata[name].required in defaultMetadata) {

            // required field, conditional
            const key = defaultMetadata[name].required;
            if (metadata[key] && isEmpty(value)) {
                addError(errors, name, "Field is required");
            }

        }
    }

    // options

    if (defaultMetadata[name].options && defaultMetadata[name].checked) {
        if (!isEmpty(value)) {
            let options = defaultMetadata[name].options.map(x => x.value);
            if (!options.includes(value)) {
                addError(errors, name, "Value \"" + value + "\" does not match any of the options (" + options.join(", ") + ")");
            }
        }
    }

    // formats

    if (value && defaultMetadata[name].email) {
        if (!validateEmail(value)) {
            addError(errors, name, "Not a valid e-mail address");
        }
    }

    if (value && defaultMetadata[name].phone) {
        if (!validatePhone(value)) {
            addError(errors, name, "Not a valid phone number");
        }
    }

    if (value && defaultMetadata[name].date) {
        if (!validateDate(value)) {
            addError(errors, name, "Not a valid date");
        }
    }

    if (value && defaultMetadata[name].numeric) {
        if (!validateNumeric(value)) {
            addError(errors, name, "Not numeric");
        }
    }

    if (value && defaultMetadata[name].expocode) {
        if (!validateExpoCode(value)) {
            addError(errors, name, "Not a valid expo code");
        }
    }

    if (value && defaultMetadata[name].url) {
        if (!validateUrl(value)) {
            addError(errors, name, "Not a valid URL");
        }
    }

    if (value && defaultMetadata[name].doi) {
        if (!validateDoi(value)) {
            addError(errors, name, "Not a valid DOI");
        }
    }

    return errors;
};

/**
 * Validates all metadata fields using validateField().
 */
const validate = function(metadata) {
    let errors = {};
    let keys = [];
    Object.keys(metadata).forEach(key => {
        keys.push(key);
    });
    Object.keys(defaultMetadata).forEach(key => {
        if (!(key in metadata) && !key.startsWith("custom_")) {
            keys.push(key);
        }
    });
    keys.forEach(key => {
        const e = validateField(key, metadata);
        Object.assign(errors, e);
    });
    return errors;
};

module.exports = {
    validate,
    validateField,
    validateEmail,
    validatePhone,
    validateDate,
    validateNumeric,
    validateDoi,
    validateUrl,
    validateExpoCode
};