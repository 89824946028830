const options_country = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "Angola", label: "Angola" },
    { value: "Argentina", label: "Argentina" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Brazil", label: "Brazil" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Cabo Verde", label: "Cabo Verde" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czechia", label: "Czechia" },
    { value: "Democratic People's Republic of Korea", label: "Democratic People's Republic of Korea" },
    { value: "Congo (Democratic Republic)", label: "Congo (Democratic Republic)" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Greece", label: "Greece" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea Bissau", label: "Guinea Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    { value: "Honduras", label: "Honduras" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran (Islamic Republic of)", label: "Iran (Islamic Republic of)" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Libya", label: "Libya" },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Malta", label: "Malta" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mexico", label: "Mexico" },
    { value: "Monaco", label: "Monaco" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Niue", label: "Niue" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Qatar", label: "Qatar" },
    { value: "Republic of Korea", label: "Republic of Korea" },
    { value: "Romania", label: "Romania" },
    { value: "Russian Federation", label: "Russian Federation" },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
    { value: "Samoa", label: "Samoa" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad & Tobago", label: "Trinidad & Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom of Great Britain & Northern Ireland", label: "United Kingdom of Great Britain & Northern Ireland" },
    { value: "United Republic of Tanzania", label: "United Republic of Tanzania" },
    { value: "United States of America", label: "United States of America" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Venezuela (Bolivarian Republic of)", label: "Venezuela (Bolivarian Republic of)" },
    { value: "Viet Nam", label: "Viet Nam" },
    { value: "Yemen", label: "Yemen" }
];

const options_researcher_id_type = [
    { value: "OceanExpert", label: "OceanExpert" },
    { value: "ORCID", label: "ORCID" },
    { value: "ResearcherID", label: "ResearcherID" }
];

const options_platform_id_type = [
    { value: "ICES", label: "ICES" },
    { value: "IMO", label: "IMO" },
    { value: "WMO", label: "WMO" }
];

const options_depth_unit = [
    { value: "cm", label: "cm" },
    { value: "m", label: "m" }
];

const options_observation_type = [
    { value: "underway", label: "underway" },
    { value: "profile", label: "profile" },
    { value: "time series", label: "time series" },
    { value: "fixed coastal station", label: "fixed coastal station" }
];

const options_dic_unit = [
    { value: "mol kg-1", label: "mol kg-1" },
    { value: "mmol kg-1", label: "mmol kg-1" },
    { value: "µmol kg-1", label: "µmol kg-1" }
];

const options_temperature_unit = [
    { value: "Celsius", label: "Celsius" },
    { value: "Fahrenheit", label: "Fahrenheit" }
];

const options_salinity_unit = [
    { value: "PSU", label: "PSU" },
    { value: "PPT", label: "PPT" }
];

const options_crm_manufacturer = [
    { value: "Dickson CRM", label: "Dickson CRM" }
];

const options_field_replicate = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" }
];

const options_ta_unit = [
    { value: "mol kg-1", label: "mol kg-1" },
    { value: "mmol kg-1", label: "mmol kg-1" },
    { value: "µmol kg-1", label: "µmol kg-1" }
];

const options_pco2_unit = [
    { value: "μatm", label: "μatm" },
    { value: "ppt", label: "ppt" }
];

const options_measured_calculated = [
    { value: "Measured", label: "Measured" },
    { value: "Calculated", label: "Calculated" }
];

const options_open_closed = [
    { value: "Open", label: "Open" },
    { value: "Closed", label: "Closed" }
];

const options_curve_fitting = [
    { value: "curve fitting with least square optimisation", label: "curve fitting with least square optimisation" },
    { value: "modified gran approach", label: "modified gran approach" }
];

const options_ph_scale = [
    { value: "total scale", label: "total scale" },
    { value: "seawater scale", label: "seawater scale" },
    { value: "NBS scale", label: "NBS scale" },
    { value: "free scale", label: "free scale" },
];

const options_calibration_frequency = [
    { value: "every 3 hours", label: "every 3 hours" },
    { value: "every 6 hours", label: "every 6 hours" },
    { value: "every 10 samples", label: "every 10 samples" },
    { value: "every 20 samples", label: "every 20 samples" }
];

const options_dic_analyzing_instrument = [
    { value: "Marianda Company VINDTA 3C system", label: "Marianda Company VINDTA 3C system" },
    { value: "Marianda Company VINDTA 3D system", label: "Marianda Company VINDTA 3D system" },
    { value: "Marianda Company AIRICA system", label: "Marianda Company AIRICA system" },
    { value: "Apollo SciTech Inc. DIC Analyser AS-C3", label: "Apollo SciTech Inc. DIC Analyser AS-C3" },
    { value: "Nihon ANS Co.", label: "Nihon ANS Co." },
    { value: "SOMMA coupled to coulometer", label: "SOMMA coupled to coulometer" },
];

const options_ta_analyzing_instrument = [
    { value: "Apollo SciTech Alkalinity Titrator AS-ALK2", label: "Apollo SciTech Alkalinity Titrator AS-ALK2" },
    { value: "Marianda Company VINDTA 3S system", label: "Marianda Company VINDTA 3S system" },
    { value: "Contros HydroFIA TA", label: "Contros HydroFIA TA" },
    { value: "Total alkalinity titration system", label: "Total alkalinity titration system" },
];

const options_pfco2_analyzing_instrument = [
    { value: "Contros-Kongsberg HydroC CO2 FT", label: "Contros-Kongsberg HydroC CO2 FT" },
    { value: "Moored Autonomous pCO2 monitoring System (MAPCO2™)", label: "Moored Autonomous pCO2 monitoring System (MAPCO2™)" },
    { value: "Battelle Seaology pCO2 monitoring System (LiCor NDIR based)", label: "Battelle Seaology pCO2 monitoring System (LiCor NDIR based)" },
    { value: "Pro-OCEANUS Systems: CO2-Pro™", label: "Pro-OCEANUS Systems: CO2-Pro™" },
    { value: "Pro-OCEANUS Systems: CO2-Pro CV™", label: "Pro-OCEANUS Systems: CO2-Pro CV™" },
    { value: "Pro-OCEANUS Systems: CO2-PRO Atmosphere™", label: "Pro-OCEANUS Systems: CO2-PRO Atmosphere™" },
    { value: "Pro-OCEANUS Systems: Mini CO2™", label: "Pro-OCEANUS Systems: Mini CO2™" },
    { value: "General Oceanics GO pCO2 system", label: "General Oceanics GO pCO2 system" },
    { value: "Apollo Scitech inc. pCO2 system (AS-P2 )", label: "Apollo Scitech inc. pCO2 system (AS-P2 )" },
    { value: "Sunburst Sensors SAMI-CO2", label: "Sunburst Sensors SAMI-CO2" },
    { value: "Sunburst Sensors Superco2 system", label: "Sunburst Sensors Superco2 system" },
    { value: "Sunburst Sensors AFT-CO2", label: "Sunburst Sensors AFT-CO2" },
    { value: "Kimoto Air-Marine CO2 Automatic Monitoring System", label: "Kimoto Air-Marine CO2 Automatic Monitoring System" },
    { value: "Kimoto Portable carbon dioxide analyzer", label: "Kimoto Portable carbon dioxide analyzer" },
    { value: "CONTROS HydroC CO2 Sensor", label: "CONTROS HydroC CO2 Sensor" },
    { value: "SubCtech OceanPack System", label: "SubCtech OceanPack System" },
    { value: "Picarro CO2 System", label: "Picarro CO2 System" },
    { value: "Marianda Company SUNDANS system", label: "Marianda Company SUNDANS system" },
    { value: "Turner Designs C-sense™ in situ pCO2 Sensor", label: "Turner Designs C-sense™ in situ pCO2 Sensor" },
    { value: "Pro-OCEANUS CO2-Pro", label: "Pro-OCEANUS CO2-Pro" },
    { value: "VeGAS pCO2", label: "VeGAS pCO2" },
    { value: "flask equilibrator coupled to GC or NDIR", label: "flask equilibrator coupled to GC or NDIR" },
];

const options_ph_analyzing_instrument = [
    { value: "seaFET Ocean pH sensor (Satlantic/Sea-Bird Scientific)", label: "seaFET Ocean pH sensor (Satlantic/Sea-Bird Scientific)" },
    { value: "seapHox Ocean pH sensor (Satlantic/Sea-Bird Scientific)", label: "seapHox Ocean pH sensor (Satlantic/Sea-Bird Scientific)" },
    { value: "Deep SeaFET (Sea-Bird)", label: "Deep SeaFET (Sea-Bird)" },
    { value: "Durafet III pH electrode", label: "Durafet III pH electrode" },
    { value: "Sunburst iSAMI pH sensor", label: "Sunburst iSAMI pH sensor" },
    { value: "Sunburst SAMI pH sensor", label: "Sunburst SAMI pH sensor" },
    { value: "Sunburst AFT pH", label: "Sunburst AFT pH" },
    { value: "spectrophotometer", label: "spectrophotometer" },
];

const options_temperature_analyzing_instrument = [
    { value: "Seabird sensors", label: "Seabird sensors" },
    { value: "YSI sensors", label: "YSI sensors" },
    { value: "Fluke Hart", label: "Fluke Hart" },
];

const options_salinity_analyzing_instrument = [
    { value: "Seabird sensors", label: "Seabird sensors" },
    { value: "YSI sensors", label: "YSI sensors" },
];

const options_platform_category = [
    { value: "Fixed Ocean Time Series", label: "Fixed Ocean Time Series" },
    { value: "Mooring", label: "Mooring" },
    { value: "Coastal Monitoring Site", label: "Coastal Monitoring Site" },
    { value: "Repeat Hydrography (vessel)", label: "Repeat Hydrography (vessel)" },
    { value: "Ship-based time series (vessel)", label: "Ship-based time series (vessel)" },
    { value: "Argo float", label: "Argo float" },
    { value: "Glider", label: "Glider" },
    { value: "Voluntary Observing Ship", label: "Voluntary Observing Ship" }
];

const options_poison = [
    { value: "HgCl2", label: "HgCl2" }
];

const options_poison_volume = [
    { value: "0.02% volume of original sample", label: "0.02% volume of original sample" },
    { value: "0.05% volume of original sample", label: "0.05% volume of original sample" },
    { value: "0.1% volume of original sample", label: "0.1% volume of original sample" }
];

const options_collection_method = [
    { value: "water collection with a water sampler (e.g. Niskin)", label: "water collection with a water sampler (e.g. Niskin)" },
    { value: "water collection with bucket", label: "water collection with bucket" },
    { value: "water collection with pump", label: "water collection with pump" }
];

const options_ph_standards_values = [
    { value: "4.0", label: "4.0" },
    { value: "7.0", label: "7.0" },
    { value: "10.0", label: "10.0" }
];

const options_vented = [
    { value: "Vented", label: "Vented" },
    { value: "Not vented", label: "Not vented" }
];

const options_flag_description = [
    { value: "ODV", label: "ODV" },
    { value: "GTSPP", label: "GTSPP" },
    { value: "ARGO", label: "ARGO" },
    { value: "SEADATANET", label: "SEADATANET" },
    { value: "ESEAS", label: "ESEAS" },
    { value: "WOD", label: "WOD" },
    { value: "WODSTATION", label: "WODSTATION" },
    { value: "WOCECTD", label: "WOCECTD" },
    { value: "WOCEBOTTLE", label: "WOCEBOTTLE" },
    { value: "WOCESAMPLE", label: "WOCESAMPLE" },
    { value: "WOCE", label: "WOCE" },
    { value: "QARTOD", label: "QARTOD" },
    { value: "BODC", label: "BODC" },
    { value: "PANGAEA", label: "PANGAEA" },
    { value: "SMHI", label: "SMHI" },
    { value: "OceanSITES", label: "OceanSITES" },
    { value: "IODE", label: "IODE" }
];

const help_field_replicate = "Repetition of sample collection and measurement, e.g., triplicate samples.";
const help_analyzing_information = "Detailed description of the analyzing procedures, including the citation of the SOP used for the analysis (e.g. SOP 7;  Dickson, A.G., Sabine, C.L. and Christian, J.R.  2007.  Guide to Best Practices for Ocean CO2  Measurements).";
const help_analyzing_instrument = "Instrument that was used to analyze the water samples collected with the 'collection method' above, or the sensors that are mounted on the 'platform' to measure the water body continuously. For example, a coulometer, winkler titrator, spectrophotometer, pH meter, thermosalinograph, oxygen sensor, YSI Multiparameter Meter, etc is an analyzing instrument. We encourage you to document as many details (such as the make, model, resolution, precisions, etc) of the instrument as you can here.";
const help_collection_method = "Method that is used to collect water samples, or deploy sensors, etc. For example, bottle collection with a Niskin bottle, pump, CTD, is a collection method.";
const help_observation_type = "How the variable is observed, e.g., surface underway, profile, time series, etc.";
const help_calibration_method = "Description of the calibration procedure.";
const help_calibration_frequency = "How frequently was the calibration carried out, e.g., every 6 hours, after every 10 samples, etc.";
const help_crm_manufacturer = "Manufacturer of the Certified Reference Material (CRM), e.g., Andrew Dickson's lab at Scripps Institute of Oceanography";
const help_batch_numbers = "Batch numbers of the CRMs that were used to calibrate the instrument. If multiple CRM batches were used please state this by indicating \"several\" in this line.";
const help_uncertainty = "Uncertainty of the results (e.g., 1%, 2 μmol kg-1), or any pieces of information that are related to the quality control of the variable";
const help_quality_control = "Indicate if quality control procedures were applied and, if so, which type of quality control procedures these were";
const help_method_reference = "Citation for the method";
const help_method_changes = "Indicate if any changes were made to the method as described in the SOP, such as changes in the sample collection method, changes in storage of the sample, different volume, changes to the CRM used, etc. Please provide a detailed list of  all of the changes made.";
const help_poison_correction = "Please specify whether the reported variables were corrected for poison usage, and if so, how they were corrected";
const help_measured_calculated = "Variable is measured in-situ, or calculated from other variables. Please indicate which variables were used and which method or software package (including the version and settings) were used for the calculation.";
const help_titration = "Type of the titration used";
const help_poison = "Poison used to kill the sample";
const help_poison_volume = "How much poison is added to each sample to kill the microbes. For example, 20 μL Mercuric Chloride is added to 500 mL samples. Please state the concentration of the poison solution used, e.g 2%.";
const help_blank_correction = "Please specify whether the reported variables were corrected for blank, and if so, how they were corrected.";
const help_standard_gas_manufacturer = "Manufacturer of the CO2 standard gas.";
const help_standard_gas_concentrations = "Concentrations of the CO2 standard gases that were used to calibrate the CO2 sensor, e.g., 200, 350, 510ppm.";
const help_standard_gas_uncertainties = "Uncertainties of the CO2 standard gas, e.g., 0.5%.";
const help_flag_abbreviation = "Column header name of the data quality flag scheme applied in the data files, e.g. QC, Quality, etc.";
const help_flag_description = "Describe which data quality flag scheme was applied. For more details on the quality flag code definitions please see: https://odv.awi.de/fileadmin/user_upload/odv/misc/ODV4_QualityFlagSets.pdf";

const label_flag_abbreviation = "Abbreviation of data quality flag scheme";
const label_flag_description = "Data quality scheme (name of scheme)";

const metadata = {
    accession_number: {
        id: 2,
        label: "Accession no. of related datasets on the 14.3.1 data platform or any other database",
        help: "If you have submitted a related data set to another data platform or data base before, and want to link them to each other, please write down the Accession Number here"
    },
    metadataset_url: {
        id: 3,
        label: "URL of metadata set",
        url: true,
        help: "URL for the metadata set at the relevant data centre"
    },
    dataset_url: {
        id: 4,
        label: "URL of associated dataset",
        url: true,
        help: "URL for the data set associated to this metadata set at the relevant data centre"
    },
    dataset_doi: {
        id: 5,
        label: "DOI of dataset (if applicable)",
        doi: true,
        help: "DOI or similar identifier for the dataset, if applicable"
    },
    investigator_1_name: {
        id: 6,
        label: "Investigator 1 name",
        required: true,
        help: "Full name of the investigator (First Middle Last)"
    },
    investigator_1_institution: {
        id: 7,
        label: "Investigator 1 institution",
        required: true,
        help: "Affiliated institution of the investigator (e.g. University of Bergen, Norway)"
    },
    investigator_1_institution_id: {
        id: 8,
        label: "Investigator 1 institution ID",
        help: "Identification of the affiliated Institution (see here: https://www.oceanexpert.org)"
    },
    investigator_1_address: {
        id: 9,
        label: "Investigator 1 address",
        required: true,
        help: "Address of the affiliated institution of the investigator"
    },
    investigator_1_phone: {
        id: 10,
        label: "Investigator 1 phone",
        phone: true,
        help: "Phone number of the investigator (country code + telephone number)"
    },
    investigator_1_email: {
        id: 11,
        label: "Investigator 1 e-mail",
        email: true,
        required: true,
        help: "E-mail address of the investigator"
    },
    investigator_1_researcher_id: {
        id: 12,
        label: "Investigator 1 researcher ID",
        required: true,
        help: "We recommend to use person identifiers (OceanExpert, ORCID, Researcher ID) to unambiguously identify the investigator"
    },
    investigator_1_id_type: {
        id: 13,
        label: "Investigator 1 researcher ID type",
        required: true,
        options: options_researcher_id_type,
        default: "(select type)",
        checked: true,
        help: "Please indicate which type of researcher ID was recorded in the above row. Please select one of the following: OceanExpert, ORCID, Researcher ID."
    },
    investigator_2_name: {
        id: 14,
        label: "Investigator 2 name",
        help: "Full name of the investigator (First Middle Last)"
    },
    investigator_2_institution: {
        id: 15,
        label: "Investigator 2 institution",
        help: "Affiliated institution of the investigator (e.g. University of Bergen, Norway)"
    },
    investigator_2_institution_id: {
        id: 16,
        label: "Investigator 2 institution ID",
        help: "Identification of the affiliated Institution (see here: https://www.oceanexpert.org)"
    },
    investigator_2_address: {
        id: 17,
        label: "Investigator 2 address",
        help: "Address of the affiliated institution of the investigator"
    },
    investigator_2_phone: {
        id: 18,
        label: "Investigator 2 phone",
        phone: true,
        help: "Phone number of the investigator (country code + telephone number)"
    },
    investigator_2_email: {
        id: 19,
        label: "Investigator 2 e-mail",
        email: true,
        help: "E-mail address of the investigator"
    },
    investigator_2_researcher_id: {
        id: 20,
        label: "Investigator 2 researcher ID",
        help: "We recommend to use person identifiers (OceanExpert, ORCID, Researcher ID) to unambiguously identify the investigator"
    },
    investigator_2_id_type: {
        id: 21,
        label: "Investigator 2 researcher ID type",
        options: options_researcher_id_type,
        default: "(select type)",
        checked: true,
        help: "Please indicate which type of researcher ID was recorded in the above row. Please select one of the following: OceanExpert, ORCID, Researcher ID."
    },
    investigator_3_name: {
        id: 22,
        label: "Investigator 3 name",
        help: "Full name of the investigator (First Middle Last)"
    },
    investigator_3_institution: {
        id: 23,
        label: "Investigator 3 institution",
        help: "Affiliated institution of the investigator (e.g. University of Bergen, Norway)"
    },
    investigator_3_institution_id: {
        id: 24,
        label: "Investigator 3 institution ID",
        help: "Identification of the affiliated Institution (see here: https://www.oceanexpert.org)"
    },
    investigator_3_address: {
        id: 25,
        label: "Investigator 3 address",
        help: "Address of the affiliated institution of the investigator"
    },
    investigator_3_phone: {
        id: 26,
        label: "Investigator 3 phone",
        phone: true,
        help: "Phone number of the investigator (country code + telephone number)"
    },
    investigator_3_email: {
        id: 27,
        label: "Investigator 3 e-mail",
        email: true,
        help: "E-mail address of the investigator"
    },
    investigator_3_researcher_id: {
        id: 28,
        label: "Investigator 3 researcher ID",
        help: "We recommend to use person identifiers (OceanExpert, ORCID, Researcher ID) to unambiguously identify the investigator"
    },
    investigator_3_id_type: {
        id: 29,
        label: "Investigator 3 researcher ID type",
        options: options_researcher_id_type,
        default: "(select type)",
        checked: true,
        help: "Please indicate which type of researcher ID was recorded in the above row. Please select one of the following: OceanExpert, ORCID, Researcher ID."
    },
    data_submitter_name: {
        id: 30,
        label: "Data submitter name",
        required: true,
        help: "Full name of the person submitting the data (First Middle Last)"
    },
    data_submitter_institution: {
        id: 31,
        label: "Data submitter institution",
        required: true,
        help: "Affiliated institution of the data submitter (e.g. University of Bergen, Norway)"
    },
    data_submitter_institution_id: {
        id: 32,
        label: "Data submitter institution ID",
        help: "Identification of the affiliated Institution (see here: https://www.oceanexpert.org)"
    },
    data_submitter_address: {
        id: 33,
        label: "Data submitter address",
        required: true,
        help: "Address of the affiliated institution of the data submitter"
    },
    data_submitter_phone: {
        id: 34,
        label: "Data submitter phone",
        phone: true,
        help: "Phone number of the data submitter (country code + telephone number)"
    },
    data_submitter_email: {
        id: 35,
        label: "Data submitter e-mail",
        email: true,
        required: true,
        help: "Email address of the data submitter"
    },
    data_submitter_researcher_id: {
        id: 36,
        label: "Data submitter researcher ID",
        required: true,
        help: "We recommend to use person identifiers (OceanExpert, ORCID, Researcher ID) to unambiguously identify the data submitter"
    },
    data_submitter_id_type: {
        id: 37,
        label: "Data submitter researcher ID type",
        options: options_researcher_id_type,
        default: "(select type)",
        required: true,
        checked: true,
        help: "Please indicate which type of researcher ID was recorded in the above row. Please select one of the following: OceanExpert, ORCID, Researcher ID."
    },
    title: {
        id: 38,
        label: "Name of sampling site or title of related research project",
        help: "Provide the name of the sampling site/related research project, e.g. BATS, CARIACO"
    },
    description: {
        id: 39,
        label: "Short description including purpose of observation",
        help: "A narrative summary of the data set, including a description of the purpose of the observations"
    },
    methods: {
        id: 40,
        label: "Method(s) applied",
        help: "Specify the methodologies applied to characterize the carbonate system, including references/citations. Please describe if you made any changes to the method as it is described in the literature, e.g. modifications of sampling procedures, different bottles used for storage of samples, changes to the dye, etc. Describe precisely how the method differed and what was done instead."
    },
    first_day: {
        id: 41,
        label: "First day of measurement included in data file (UTC, YYYY-MM-DD)",
        date: true,
        help: "Start date of the first measurement (e.g. 2001-02-25). Please use ISO 8601 date format and if applicable time format (YYYY-MM-DD or YY-MM-DDTHH:MM:SS)."
    },
    last_day: {
        id: 42,
        label: "Last day of measurement included in data file (UTC, YYYY-MM-DD)",
        date: true,
        help: "End date of the last measurement (e.g. 2002-05-16). Please use ISO 8601 date format and if applicable time format (YYYY-MM-DD or YY-MM-DDTHH:MM:SS)."
    },
    longitude: {
        id: 43,
        label: "Site specific measurement longitude",
        numeric: true,
        help: "Longitude of sampling site (one station only) (decimal degrees, negative for Western Hemisphere longitude)"
    },
    latitude: {
        id: 44,
        label: "Site specific measurement latitude",
        numeric: true,
        help: "Latitude of sampling site (one station only) (decimal degrees, negative for Southern Hemisphere longitude)"
    },
    transect_longitude_east: {
        id: 45,
        label: "Site specific measurement latitude easternmost",
        numeric: true,
        help: "Easternmost longitude of the sampling (decimal degrees, negative for Western Hemisphere longitude)"
    },
    transect_longitude_west: {
        id: 46,
        label: "Transect measurement longitude westernmost",
        numeric: true,
        help: "Westernmost longitude of the sampling (decimal degrees, negative for Western Hemisphere longitude)"
    },
    transect_latitude_north: {
        id: 47,
        label: "Transect measurement latitude northernmost",
        numeric: true,
        help: "Northernmost latitude of the sampling (decimal degrees, negative for Southern Hemisphere latitude)"
    },
    transect_latitude_south: {
        id: 48,
        label: "Transect measurement latitude southernmost",
        numeric: true,
        help: "Southernmost latitude of the sampling (decimal degrees, negative for Southern Hemisphere latitude)"
    },
    funding_agency: {
        id: 49,
        label: "Funding agency",
        help: "Funding agency of the data collection. Examples include NOAA's Ocean Acidification Program, etc."
    },
    funding_project_title: {
        id: 50,
        label: "Funding project title",
        help: "The title of this funded project"
    },
    funding_project_id: {
        id: 51,
        label: "Funding project ID (grant number)",
        help: "The ID of this funded project"
    },
    platform_name: {
        id: 52,
        label: "Platform name",
        help: "Platforms may be the research vessels that carry out the research. However, platforms could be something other than a ship (e.g. glider, Argo, etc), or something that is fixed (e.g., moored buoys, towers, etc)."
    },
    platform_category: {
        id: 53,
        label: "Platform category",
        options: options_platform_category,
        default: "(select category)",
        help: "Type of the platform",
        checked: true
    },
    platform_id: {
        id: 54,
        label: "Platform ID",
        help: "Platform identifier code and specific type (e.g. ICES). For a list of ICES ship code, please check out this link: http://vocab.ices.dk."
    },
    platform_id_type: {
        id: 55,
        label: "Platform ID type",
        options: options_platform_id_type,
        default: "(select type)",
        checked: true,
        help: "Please specify which type of Platform ID was used: ICES, IMO or WMO."
    },
    platform_owner: {
        id: 56,
        label: "Platform owner",
        help: "Owner of the platform",
    },
    platform_country: {
        id: 57,
        label: "Platform country",
        help: "Country of the platform owner",
        default: "(select country)",
        options: options_country,
        checked: true
    },
    expocode: {
        id: 58,
        label: "EXPOCODE",
        expocode: true,
        help: "EXPOCODE, or the \"expedition code\", is a unique alphanumeric identifier defined by the National Oceanographic Data Center (NODC). The code defines a standard nomenclature for cruise labels of research vessels and intends to avoid confusion in oceanographic data management. Format: NODCYYYYMMDD; NODC is NOAA's National Oceanographic Data Center's 4-character research vessel identifier, consisting of country and ship code, YYYYMMDD is the GMT date when the cruise left port. https://www.nodc.noaa.gov/General/NODC-Archive/platformlist.txt "
    },
    cruise_id: {
        id: 59,
        label: "Cruise ID",
        help: "Cruise ID is the particular ship cruise number (e.g., MT901), or other alias for the cruise. For example, the cruise ID (e.g., A16N_2013) could consist of a Section ID (e.g. A16N), and the sampling year (e.g. 2013). Cruise ID types can include ICES, IMO, WMO."
    },
    cruise_id_type: {
        id: 60,
        label: "Cruise ID type",
        default: "(select type)",
        options: options_platform_id_type,
        help: "Please specify which type of Cruise ID was used: ICES, IMO or WMO"
    },
    author_list: {
        id: 61,
        label: "Author list for citation",
        help: "Please provide the list of authors in their correct order for the creation of data citation for this data set. We recommend the format of Lastname1, Firstname1 Middlename1; Lastname2, Firstname2 Middlename2 for this field.",
        required: true
    },
    references: {
        id: 62,
        label: "References",
        help: "References are bibliographic citations of publications or cruise reports etc., describing the data set"
    },
    supplemental_information: {
        id: 63,
        label: "Supplemental information",
        help: "Any additional information that is critical to the understanding of the data set, but does not fit into other existing fields"
    },
    depth_variable_abbreviation: {
        id: 64,
        label: "Depth variable abbreviation in data files",
        required: true,
        help: "Column header name of the variable in the data files, e.g. Depth"
    },
    depth_variable_unit: {
        id: 65,
        label: "Depth variable unit",
        required: true,
        default: "(select unit)",
        options: options_depth_unit,
        checked: true,
        help: "Unit of the variable (e.g. m, cm)"
    },
    dic_variable_abbreviation: {
        id: 66,
        label: "Variable abbreviation",
        help: "Column header name of the variable in the data files, e.g., DIC, TCO2, CT"
    },
    dic_observation_type: {
        id: 67,
        label: "Observation type",
        default: "(select type)",
        options: options_observation_type,
        help: help_observation_type,
        checked: true,
        required: "dic_variable_abbreviation"
    },
    dic_variable_unit: {
        id: 68,
        label: "Variable unit",
        default: "(select unit)",
        options: options_dic_unit,
        checked: true,
        required: "dic_variable_abbreviation",
        help: "Units of the variable (e.g., μmol kg-1)"
    },
    dic_collection_method: {
        id: 69,
        label: "Collection method (e.g. bottle sampling)",
        help: help_collection_method,
        required: "dic_variable_abbreviation",
        options: options_collection_method
    },
    dic_analyzing_instrument: {
        id: 70,
        label: "Analyzing instrument",
        default: "(select instrument)",
        options: options_dic_analyzing_instrument,
        help: help_analyzing_instrument
    },
    dic_analyzing_information: {
        id: 71,
        label: "Analyzing information with citation",
        help: help_analyzing_information
    },
    dic_quality_control: {
        id: 72,
        label: "Quality control",
        help: help_quality_control
    },
    dic_flag_abbreviation: {
        id: 73,
        label: label_flag_abbreviation,
        help: help_flag_abbreviation
    },
    dic_flag_description: {
        id: 74,
        label: label_flag_description,
        help: help_flag_description,
        options: options_flag_description
    },
    dic_uncertainty: {
        id: 75,
        label: "Uncertainty",
        help: help_uncertainty
    },
    dic_field_replicate: {
        id: 76,
        label: "Field replicate information",
        default: "(select)",
        options: options_field_replicate,
        help: help_field_replicate
    },
    dic_calibration_method: {
        id: 77,
        label: "Calibration method",
        help: help_calibration_method
    },
    dic_calibration_frequency: {
        id: 78,
        label: "Frequency of calibration",
        options: options_calibration_frequency,
        help: help_calibration_frequency
    },
    dic_crm_manufacturer: {
        id: 79,
        label: "CRM manufacturer",
        default: "(select manufacturer)",
        options: options_crm_manufacturer,
        help: help_crm_manufacturer
    },
    dic_batch_numbers: {
        id: 80,
        label: "Batch number(s)",
        help: help_batch_numbers
    },
    dic_poison: {
        id: 81,
        label: "Poison used to kill the sample",
        help: help_poison,
        options: options_poison
    },
    dic_poison_volume: {
        id: 82,
        label: "Poison volume",
        options: options_poison_volume,
        help: help_poison_volume
    },
    dic_poison_correction: {
        id: 83,
        label: "Poisoning correction description",
        help: help_poison_correction
    },
    dic_method_reference: {
        id: 84,
        label: "Method reference (citation)",
        help: help_method_reference
    },
    dic_method_changes: {
        id: 85,
        label: "Changes to method or SOP",
        help: help_method_changes
    },
    ta_variable_abbreviation: {
        id: 86,
        label: "Variable abbreviation",
        help: "Column header name of the variable in the data files, e.g., TA, Alk, AT"
    },
    ta_observation_type: {
        id: 87,
        label: "Observation type",
        default: "(select type)",
        options: options_observation_type,
        help: help_observation_type,
        checked: true
    },
    ta_variable_unit: {
        id: 88,
        label: "Variable unit",
        default: "(select unit)",
        options: options_ta_unit,
        required: "ta_variable_abbreviation",
        help: "Units of the variable (e.g., μmol/kg)",
        checked: true
    },
    ta_collection_method: {
        id: 89,
        label: "Collection method (e.g. bottle sampling)",
        help: help_collection_method,
        required: "ta_variable_abbreviation",
        options: options_collection_method
    },
    ta_measured_calculated: {
        id: 90,
        label: "Measured or calculated",
        default: "(select)",
        options: options_measured_calculated,
        required: "ta_variable_abbreviation",
        help: help_measured_calculated,
        checked: true
    },
    ta_calculation_method: {
        id: 91,
        label: "Calculation method and parameters",
        help: "Variable is measured in-situ, or calculated from other variables. Please indicate which variables were used and which method or software package (including the version and settings) were used for the calculation."
    },
    ta_analyzing_instrument: {
        id: 92,
        label: "Analyzing instrument",
        default: "(select instrument)",
        options: options_ta_analyzing_instrument,
        help: help_analyzing_instrument
    },
    ta_analyzing_information: {
        id: 93,
        label: "Analyzing information with citation (SOP etc.)",
        help: help_analyzing_information
    },
    ta_quality_control: {
        id: 94,
        label: "Quality control",
        help: help_quality_control
    },
    ta_flag_abbreviation: {
        id: 95,
        label: label_flag_abbreviation,
        help: help_flag_abbreviation
    },
    ta_flag_description: {
        id: 96,
        label: label_flag_description,
        help: help_flag_description,
        options: options_flag_description
    },
    ta_uncertainty: {
        id: 97,
        label: "Uncertainty",
        help: help_uncertainty
    },
    ta_titration_type: {
        id: 98,
        label: "Type of titration",
        help: help_titration
    },
    ta_cell_type: {
        id: 99,
        label: "Cell type (open or closed)",
        default: "(select)",
        options: options_open_closed,
        help: "Whether the titration cell used was open or closed",
        checked: true
    },
    ta_curve_fitting: {
        id: 100,
        label: "Curve fitting method",
        default: "(select method)",
        options: options_curve_fitting,
        help: "Curve fitting method used to determine the alkalinity"
    },
    ta_field_replicate: {
        id: 101,
        label: "Field replicate information",
        default: "(select)",
        options: options_field_replicate,
        help: help_field_replicate
    },
    ta_calibration_method: {
        id: 102,
        label: "Calibration method",
        help: help_calibration_method
    },
    ta_calibration_frequency: {
        id: 103,
        label: "Frequency of calibration",
        options: options_calibration_frequency,
        help: help_calibration_frequency
    },
    ta_crm_manufacturer: {
        id: 104,
        label: "CRM manufacturer",
        default: "(select manufacturer)",
        options: options_crm_manufacturer,
        help: help_crm_manufacturer
    },
    ta_batch_numbers: {
        id: 105,
        label: "Batch number(s)",
        help: help_batch_numbers
    },
    ta_poison: {
        id: 106,
        label: help_poison,
        options: options_poison,
        help: "As described, e.g., Mercuric Chloride"
    },
    ta_poison_volume: {
        id: 107,
        label: "Poison volume",
        options: options_poison_volume,
        help: help_poison_volume
    },
    ta_poison_correction: {
        id: 108,
        label: "Poisoning correction description",
        help: help_poison_correction
    },
    ta_blank_correction: {
        id: 109,
        label: "Magnitude of blank correction",
        help: help_blank_correction
    },
    ta_method_reference: {
        id: 110,
        label: "Method reference (citation)",
        help: help_method_reference
    },
    ta_method_changes: {
        id: 111,
        label: "Changes to method or SOP",
        help: help_method_changes
    },
    ph_variable_abbreviation: {
        id: 112,
        label: "Variable abbreviation",
        help: "Column header name of the variable in the data files, e.g., pH"
    },
    ph_observation_type: {
        id: 113,
        label: "Observation type",
        default: "(select type)",
        options: options_observation_type,
        help: help_observation_type,
        required: "ph_variable_abbreviation",
        checked: true
    },
    ph_collection_method: {
        id: 114,
        label: "Collection method (e.g. bottle sampling)",
        options: options_collection_method,
        help: help_collection_method,
        required: "ph_variable_abbreviation"
    },
    ph_analyzing_instrument: {
        id: 115,
        label: "Analyzing instrument",
        default: "(select instrument)",
        options: options_ph_analyzing_instrument,
        help: help_analyzing_instrument
    },
    ph_analyzing_information: {
        id: 116,
        label: "Analyzing information with citation",
        help: help_analyzing_information
    },
    ph_scale: {
        id: 117,
        label: "pH scale",
        default: "(select scale)",
        options: options_ph_scale,
        required: "ph_variable_abbreviation",
        help: "The pH scale for the reported pH results, e.g. total scale, seawater scale, NBS scale, etc. We strongly recommend the use of the total scale for pH.",
        checked: true
    },
    ph_quality_control: {
        id: 118,
        label: "Quality control",
        help: help_quality_control
    },
    ph_flag_abbreviation: {
        id: 119,
        label: label_flag_abbreviation,
        help: help_flag_abbreviation
    },
    ph_flag_description: {
        id: 120,
        label: label_flag_description,
        help: help_flag_description,
        options: options_flag_description
    },
    ph_uncertainty: {
        id: 121,
        label: "Uncertainty",
        help: help_uncertainty
    },
    ph_measurement_temperature: {
        id: 122,
        label: "Temperature of measurement (°C)",
        numeric: true,
        help: "Temperature at which the samples were measured (°C)"
    },
    ph_field_replicate: {
        id: 123,
        label: "Field replicate information",
        default: "(select)",
        options: options_field_replicate,
        help: help_field_replicate
    },
    ph_calibration_method: {
        id: 124,
        label: "Calibration method",
        help: help_calibration_method
    },
    ph_calibration_frequency: {
        id: 125,
        label: "Frequency of calibration",
        options: options_calibration_frequency,
        help: help_calibration_frequency
    },
    ph_dye_type: {
        id: 126,
        label: "Type of dye and manufacturer information",
        help: "Please state the exact type of dye used and the name and address of the manufacturer and brand of the dye."
    },
    ph_standards_values: {
        id: 127,
        label: "pH values of the standards",
        help: "pH values of the standards, e.g., 4.0, 7.0, 10.0",
        options: options_ph_standards_values
    },
    ph_calibration_temperature: {
        id: 128,
        label: "Temperature of calibration",
        numeric: true,
        help: "Temperature at which the calibration was done"
    },
    ph_temperature_correction: {
        id: 129,
        label: "Temperature correction method",
        help: "How the temperature effect was corrected"
    },
    ph_reporting_temperature: {
        id: 130,
        label: "At what temperature was pH reported",
        numeric: true,
        help: "The input could be a constant temperature value, or something like in-situ temperature, temperature of analysis, etc."
    },
    ph_method_reference: {
        id: 131,
        label: "Method reference (citation)",
        help: help_method_reference
    },
    ph_method_changes: {
        id: 132,
        label: "Changes to method or SOP",
        help: help_method_changes
    },
    pco2_variable_abbreviation: {
        id: 133,
        label: "Variable abbreviation",
        help: "Column header name of the variable in the data files, e.g., pCO2, etc."
    },
    pco2_observation_type: {
        id: 134,
        label: "Observation type",
        default: "(select type)",
        options: options_observation_type,
        help: help_observation_type,
        checked: true
    },
    pco2_variable_unit: {
        id: 135,
        label: "Variable unit",
        default: "(select unit)",
        options: options_pco2_unit,
        required: "pco2_variable_abbreviation",
        help: "Units of the variable, e.g., μatm",
        checked: true
    },
    pco2_collection_method: {
        id: 136,
        label: "Collection method (e.g. bottle sampling)",
        options: options_collection_method,
        help: help_collection_method,
        required: "pco2_variable_abbreviation"
    },
    pco2_seawater_intake_location: {
        id: 137,
        label: "Location of seawater intake",
        help: "Whereabouts of the seawater intake"
    },
    pco2_seawater_intake_depth: {
        id: 138,
        label: "Depth of seawater intake (m)",
        numeric: true,
        help: "Water depth of the seawater intake"
    },
    pco2_analyzing_instrument: {
        id: 139,
        label: "Analyzing instrument",
        default: "(select instrument)",
        options: options_pfco2_analyzing_instrument,
        help: help_analyzing_instrument
    },
    pco2_analyzing_information: {
        id: 140,
        label: "Analyzing information with citation",
        help: help_analyzing_information
    },
    pco2_quality_control: {
        id: 141,
        label: "Quality control",
        help: help_quality_control
    },
    pco2_flag_abbreviation: {
        id: 142,
        label: label_flag_abbreviation,
        help: help_flag_abbreviation
    },
    pco2_flag_description: {
        id: 143,
        label: label_flag_description,
        help: help_flag_description,
        options: options_flag_description
    },
    pco2_uncertainty: {
        id: 144,
        label: "Uncertainty",
        help: help_uncertainty
    },
    pco2_equilibrator_type: {
        id: 145,
        label: "Equilibrator type",
        help: "Type of the equilibrator for the CO2 measurement"
    },
    pco2_equilibrator_volume: {
        id: 146,
        label: "Equilibrator volume (L)",
        numeric: true,
        help: "The total volume of the CO2 equilibrator"
    },
    pco2_equilibrator_vented: {
        id: 147,
        label: "Equilibrator vented or not",
        default: "(select)",
        options: options_vented,
        help: "Is the equilibrator vented or not?",
        checked: true
    },
    pco2_equilibrator_water_flow: {
        id: 148,
        label: "Equilibrator water flow rate (L min-1)",
        numeric: true,
        help: "Flow rate of the seawater flow through"
    },
    pco2_equilibrator_headspace_flow: {
        id: 149,
        label: "Equilibrator headspace gas flow rate (L min-1)",
        numeric: true,
        help: "Flow rate of the gas from the equilibrator to the CO2 analyzer"
    },
    pco2_equilibrator_temperature_measured: {
        id: 150,
        label: "How was temperature inside the equilibrator measured",
        help: "Please specify whether the temperature inside the equilibrator was measured or not. If so, please describe how the temperature was measured."
    },
    pco2_equilibrator_pressure_measured: {
        id: 151,
        label: "How was pressure inside the equilibrator measured",
        help: "Please specify whether the pressure inside the equilibrator was measured or not. If so, please describe how the pressure was measured."
    },
    pco2_drying_method: {
        id: 152,
        label: "Drying method for CO2 gas",
        help: "The method used to dry the gas coming out of CO2 equilibrator, before it is pumped into the CO2 sensor."
    },
    pco2_gas_detector_manufacturer: {
        id: 153,
        label: "Manufacturer of the gas detector",
        help: "Manufacturer of the CO2 sensor"
    },
    pco2_gas_detector_model: {
        id: 154,
        label: "Model of the gas detector",
        help: "Model number of the CO2 sensor"
    },
    pco2_gas_detector_resolution: {
        id: 155,
        label: "Resolution of the gas detector",
        help: "Resolution of the CO2 sensor"
    },
    pco2_gas_detector_uncertainty: {
        id: 156,
        label: "Uncertainty of the gas detector",
        help: "Uncertainity of the CO2 sensor"
    },
    pco2_calibration_method: {
        id: 157,
        label: "Calibration method",
        help: help_calibration_method
    },
    pco2_calibration_frequency: {
        id: 158,
        label: "Calibration frequency",
        default: "(select frequency)",
        options: options_calibration_frequency,
        help: help_calibration_frequency
    },
    pco2_standard_gas_manufacturer: {
        id: 159,
        label: "Manufacturer of standard gas",
        help: help_standard_gas_manufacturer
    },
    pco2_standard_gas_concentrations: {
        id: 160,
        label: "Concentrations of standard gas",
        help: help_standard_gas_concentrations
    },
    pco2_standard_gas_uncertainties: {
        id: 161,
        label: "Uncertainties of standard gas",
        help: help_standard_gas_uncertainties
    },
    pco2_water_vapor_correction_method: {
        id: 162,
        label: "Water vapor correction method",
        help: "How the water vapor pressure inside the equilibrator was determined"
    },
    pco2_temperature_correction_method: {
        id: 163,
        label: "Temperature correction method",
        help: "How the temperature effect was corrected."
    },
    pco2_temperature_reported: {
        id: 164,
        label: "At what temperature was pCO2 reported",
        numeric: true,
        help: "The input could be a constant temperature value, or something like in-situ temperature, temperature of analysis, etc."
    },
    pco2_method_reference: {
        id: 165,
        label: "Method reference (citation)",
        help: help_method_reference
    },
    pco2_method_changes: {
        id: 166,
        label: "Changes to method or SOP",
        help: help_method_changes
    },
    fco2_variable_abbreviation: {
        id: 167,
        label: "Variable abbreviation",
        help: "Column header name of the variable in the data files, e.g., fCO2, etc."
    },
    fco2_observation_type: {
        id: 168,
        label: "Observation type",
        default: "(select type)",
        options: options_observation_type,
        help: help_observation_type,
        checked: true
    },
    fco2_variable_unit: {
        id: 169,
        label: "Variable unit",
        default: "(select unit)",
        options: options_pco2_unit,
        required: "fco2_variable_abbreviation",
        help: "Units of the variable, e.g., μatm",
        checked: true
    },
    fco2_collection_method: {
        id: 170,
        label: "Collection method (e.g. bottle sampling)",
        options: options_collection_method,
        help: help_collection_method,
        required: "fco2_variable_abbreviation"
    },
    fco2_seawater_intake_location: {
        id: 171,
        label: "Location of seawater intake",
        help: "Whereabouts of the seawater intake"
    },
    fco2_seawater_intake_depth: {
        id: 172,
        label: "Depth of seawater intake (m)",
        numeric: true,
        help: "Water depth of the seawater intake"
    },
    fco2_analyzing_instrument: {
        id: 173,
        label: "Analyzing instrument",
        default: "(select instrument)",
        options: options_pfco2_analyzing_instrument,
        help: help_analyzing_instrument
    },
    fco2_analyzing_information: {
        id: 174,
        label: "Analyzing information with citation",
        help: help_analyzing_information
    },
    fco2_quality_control: {
        id: 175,
        label: "Quality control",
        help: help_quality_control
    },
    fco2_flag_abbreviation: {
        id: 176,
        label: label_flag_abbreviation,
        help: help_flag_abbreviation
    },
    fco2_flag_description: {
        id: 177,
        label: label_flag_description,
        help: help_flag_description,
        options: options_flag_description
    },
    fco2_uncertainty: {
        id: 178,
        label: "Uncertainty",
        help: help_uncertainty
    },
    fco2_equilibrator_type: {
        id: 179,
        label: "Equilibrator type",
        help: "Type of the equilibrator for the CO2 measurement"
    },
    fco2_equilibrator_volume: {
        id: 180,
        label: "Equilibrator volume (L)",
        numeric: true,
        help: "The total volume of the CO2 equilibrator"
    },
    fco2_equilibrator_vented: {
        id: 181,
        label: "Equilibrator vented or not",
        default: "(select)",
        options: options_vented,
        help: "Is the equilibrator vented or not?",
        checked: true
    },
    fco2_equilibrator_water_flow: {
        id: 182,
        label: "Equilibrator water flow rate (L min-1)",
        numeric: true,
        help: "Flow rate of the seawater flow through"
    },
    fco2_equilibrator_headspace_flow: {
        id: 183,
        label: "Equilibrator headspace gas flow rate (L min-1)",
        numeric: true,
        help: "Flow rate of the gas from the equilibrator to the CO2 analyzer"
    },
    fco2_equilibrator_temperature_measured: {
        id: 184,
        label: "How was temperature inside the equilibrator measured",
        help: "Please specify whether the temperature inside the equilibrator was measured or not. If so, please describe how the temperature was measured."
    },
    fco2_equilibrator_pressure_measured: {
        id: 185,
        label: "How was pressure inside the equilibrator measured",
        help: "Please specify whether the pressure inside the equilibrator was measured or not. If so, please describe how the pressure was measured."
    },
    fco2_drying_method: {
        id: 186,
        label: "Drying method for CO2 gas",
        help: "The method used to dry the gas coming out of CO2 equilibrator, before it is pumped into the CO2 sensor."
    },
    fco2_gas_detector_manufacturer: {
        id: 187,
        label: "Manufacturer of the gas detector",
        help: "Manufacturer of the CO2 sensor"
    },
    fco2_gas_detector_model: {
        id: 188,
        label: "Model of the gas detector",
        help: "Model number of the CO2 sensor"
    },
    fco2_gas_detector_resolution: {
        id: 189,
        label: "Resolution of the gas detector",
        help: "Resolution of the CO2 sensor"
    },
    fco2_gas_detector_uncertainty: {
        id: 190,
        label: "Uncertainty of the gas detector",
        help: "Uncertainity of the CO2 sensor"
    },
    fco2_calibration_method: {
        id: 191,
        label: "Calibration method",
        help: help_calibration_method
    },
    fco2_calibration_frequency: {
        id: 192,
        label: "Calibration frequency",
        default: "(select frequency)",
        options: options_calibration_frequency,
        help: help_calibration_frequency
    },
    fco2_standard_gas_manufacturer: {
        id: 193,
        label: "Manufacturer of standard gas",
        help: help_standard_gas_manufacturer
    },
    fco2_standard_gas_concentrations: {
        id: 194,
        label: "Concentrations of standard gas",
        help: help_standard_gas_concentrations
    },
    fco2_standard_gas_uncertainties: {
        id: 195,
        label: "Uncertainties of standard gas",
        help: help_standard_gas_uncertainties
    },
    fco2_water_vapor_correction_method: {
        id: 196,
        label: "Water vapor correction method",
        help: "How the water vapor pressure inside the equilibrator was determined"
    },
    fco2_temperature_correction_method: {
        id: 197,
        label: "Temperature correction method",
        help: "How the temperature effect was corrected."
    },
    fco2_temperature_reported: {
        id: 198,
        label: "At what temperature was fCO2 reported",
        numeric: true,
        help: "The input could be a constant temperature value, or something like in-situ temperature, temperature of analysis, etc."
    },
    fco2_method_reference: {
        id: 199,
        label: "Method reference (citation)",
        help: help_method_reference
    },
    fco2_method_changes: {
        id: 200,
        label: "Changes to method or SOP",
        help: help_method_changes
    },
    temperature_variable_abbreviation: {
        id: 201,
        label: "Variable abbreviation in data files",
        help: "Column header name of the variable in the data files, e.g. Temp, T"
    },
    temperature_observation_type: {
        id: 202,
        label: "Observation type",
        default: "(select type)",
        options: options_observation_type,
        help: help_observation_type,
        required: "temperature_variable_abbreviation",
        checked: true
    },
    temperature_variable_unit: {
        id: 203,
        label: "Variable unit",
        default: "(select unit)",
        options: options_temperature_unit,
        required: "temperature_variable_abbreviation",
        help: "Units of the variable, e.g. °C, K"
    },
    temperature_collection_method: {
        id: 204,
        label: "Collection method (e.g. bottle sampling)",
        options: options_collection_method,
        help: help_collection_method,
        required: "temperature_variable_abbreviation"
    },
    temperature_analyzing_instrument: {
        id: 205,
        label: "Analyzing instrument",
        default: "(select intrument)",
        options: options_temperature_analyzing_instrument,
        help: help_analyzing_instrument,
        required: "temperature_variable_abbreviation"
    },
    temperature_analyzing_information: {
        id: 206,
        label: "Analyzing information with citation",
        help: help_analyzing_information
    },
    temperature_quality_control: {
        id: 207,
        label: "Quality control",
        help: help_quality_control
    },
    temperature_flag_abbreviation: {
        id: 208,
        label: label_flag_abbreviation,
        help: help_flag_abbreviation
    },
    temperature_flag_description: {
        id: 209,
        label: label_flag_description,
        help: help_flag_description,
        options: options_flag_description
    },
    temperature_uncertainty: {
        id: 210,
        label: "Uncertainty",
        help: help_uncertainty
    },
    temperature_field_replicate: {
        id: 211,
        label: "Field replicate information",
        default: "(select)",
        options: options_field_replicate,
        help: help_field_replicate
    },
    temperature_method_reference: {
        id: 212,
        label: "Method reference (citation)",
        help: help_method_reference
    },
    temperature_method_changes: {
        id: 213,
        label: "Changes to method or SOP",
        help: help_method_changes
    },
    salinity_variable_abbreviation: {
        id: 214,
        label: "Variable abbreviation in data files",
        help: "Column header name of the variable in the data files, e.g., SAL, S"
    },
    salinity_observation_type: {
        id: 215,
        label: "Observation type",
        default: "(select type)",
        options: options_observation_type,
        required: "salinity_variable_abbreviation",
        help: help_observation_type,
        checked: true
    },
    salinity_variable_unit: {
        id: 216,
        label: "Variable unit",
        default: "(select unit)",
        options: options_salinity_unit,
        required: "salinity_variable_abbreviation",
        help: "Units of the variable, e.g. PSU, PPT",
        checked: true
    },
    salinity_collection_method: {
        id: 217,
        label: "Collection method (e.g. bottle sampling)",
        options: options_collection_method,
        help: help_collection_method,
        required: "salinity_variable_abbreviation"
    },
    salinity_analyzing_instrument: {
        id: 218,
        label: "Analyzing instrument",
        default: "(select instrument)",
        options: options_salinity_analyzing_instrument,
        help: help_analyzing_instrument,
        required: "salinity_variable_abbreviation"
    },
    salinity_analyzing_information: {
        id: 219,
        label: "Analyzing information with citation",
        help: help_analyzing_information
    },
    salinity_quality_control: {
        id: 220,
        label: "Quality control",
        help: help_quality_control
    },
    salinity_flag_abbreviation: {
        id: 221,
        label: label_flag_abbreviation,
        help: help_flag_abbreviation
    },
    salinity_flag_description: {
        id: 222,
        label: label_flag_description,
        help: help_flag_description,
        options: options_flag_description
    },
    salinity_uncertainty: {
        id: 223,
        label: "Uncertainty",
        help: help_uncertainty
    },
    salinity_field_replicate: {
        id: 224,
        label: "Field replicate information",
        default: "(select)",
        options: options_field_replicate,
        help: help_field_replicate
    },
    salinity_method_reference: {
        id: 225,
        label: "Method reference (citation)",
        help: help_method_reference
    },
    salinity_method_changes: {
        id: 226,
        label: "Changes to method or SOP",
        help: help_method_changes
    },
    custom_variable_abbreviation: {
        id: 227,
        label: "Variable abbreviation in data files",
        help: "Column header name of the variable in the data files, e.g., DO, NIT, DIP etc"
    },
    custom_variable_name: {
        id: 228,
        label: "Full variable name",
        help: "Here \"Variable\" refers to an observed feature of your project, e.g.,  Dissolved Oxygen, Nitrate, dissolved inorganic phosphate  etc. Information such as Station ID, Bottle number, etc are not variables. Similarly, ancillary variables, such as Nitrate_stdev, Nitrate_Flag, temp_eq, press_eq, etc are not treated as \"Variables\" either. In this example, only their parent variable (Nitrate, and pCO2) are treated as variables."
    },
    custom_observation_type: {
        id: 229,
        label: "Observation type",
        default: "(select type)",
        options: options_observation_type,
        help: help_observation_type
    },
    custom_variable_unit: {
        id: 230,
        label: "Variable unit",
        help: "Units of the variable, e.g. μmol kg-1"
    },
    custom_collection_method: {
        id: 231,
        label: "Collection method (e.g. bottle sampling)",
        options: options_collection_method,
        help: help_collection_method
    },
    custom_analyzing_instrument: {
        id: 232,
        label: "Analyzing instrument",
        help: help_analyzing_instrument
    },
    custom_analyzing_information: {
        id: 233,
        label: "Analyzing information with citation",
        help: help_analyzing_information
    },
    custom_quality_control: {
        id: 234,
        label: "Quality control",
        help: help_quality_control
    },
    custom_flag_abbreviation: {
        id: 235,
        label: label_flag_abbreviation,
        help: help_flag_abbreviation
    },
    custom_flag_description: {
        id: 236,
        label: label_flag_description,
        help: help_flag_description,
        options: options_flag_description
    },
    custom_uncertainty: {
        id: 237,
        label: "Uncertainty",
        help: help_uncertainty
    },
    custom_field_replicate: {
        id: 238,
        label: "Field replicate information",
        default: "(select)",
        options: options_field_replicate,
        help: help_field_replicate
    },
    custom_method_reference: {
        id: 239,
        label: "Method reference (citation)",
        help: help_method_reference
    },
    custom_method_changes: {
        id: 240,
        label: "Changes to method or SOP",
        help: help_method_changes
    }
};

module.exports = {
    metadata,
    options_country,
    options_platform_category
};