import React, { PureComponent } from "react";
import Info from "./Info";
import Required from "./Required";

export default class SelectYesNoOther extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            text: "",
            selected: "no"
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static getDerivedStateFromProps(props) {
        if (props.value === "no") {
            return {
                selected: "no",
                text: ""
            };
        } else if (props.value === "yes") {
            return {
                selected: "yes",
                text: ""
            };
        } else {
            return {
                selected: "yes",
                text: props.value
            };
        }
    }

    handleChange(e) {
        this.props.handleChange({
            "target": {
                "name": this.props.name,
                "value": e.target.value
            }
        });
    }

    render() {
        return (
            <div className={`form-group col ${this.props.col}`}>
                <label>
                    <span className="mr-2">{ this.props.label }</span>
                    { this.props.required && <Required required={ this.props.required } /> }
                    { this.props.help && <Info help={ this.props.help }></Info> }
                </label>
                <select name={ this.props.name } value={ this.state.selected } className={`form-control ${this.props.error ? "is-invalid" : "" }`} onChange={ this.handleChange }>
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                </select>
                {
                    this.state.selected !== "no" &&
                        <div>
                            <label className="mt-3">{this.props.label} (specify)</label>
                            <input type="text" className="form-control" value={this.state.text} onChange={this.handleChange}></input>
                        </div>
                }
            </div>
        )
    }
}