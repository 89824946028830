import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { metadata as defaultMetadata } from "../lib/model";
import FormPanel from "./FormPanel";
import Select from "./Select";
import SelectOther from "./SelectOther";
import InputField from "./InputField";
import { validateField, validate } from "../lib/validate";
import SelectYesNoOther from "./SelectYesNoOther";
import CustomVariable from "./CustomVariable";
import debounce from "lodash.debounce";
import ErrorList from "./ErrorList";

class SubmissionForm extends Component {
    constructor(props) {
        super(props);
        const fields = Object.assign({}, ...Object.entries(defaultMetadata).filter(o => !(o[0].startsWith("custom_"))).map(o => ({ [o[0]]: "" }) ));
        this.state = {
            ...fields,
            custom: [],
            id: null,
            errors: {},
            showErrors: false,
            dirty: false
        };
        this.debounceValidateField = debounce(this.doValidateField, 500);
        this.handleSave = this.handleSave.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.doBack = this.doBack.bind(this);
        this.handleAddVariable = this.handleAddVariable.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Validates a single field and sets error for this field to true or false.
     */
    doValidateField(name) {
        const result = validateField(name, this.state);
        if (name in result && result[name].length > 0) {
            this.setError(name, result[name]);
        } else {
            this.setError(name, null);
        }
    }

    /**
     * Updates errors for a single field.
     */
    setError(key, errors) {
        let newErrors = {
            ...this.state.errors,
            [key]: errors
        };
        if (!errors) {
            delete newErrors[key];
        }
        this.setState({
            errors: newErrors
        });
    }

    /**
     * Does a full metadata validation.
     */
    validate(callback) {
        let metadata = {};
        Object.keys(this.state).forEach(key => {
            if (key in defaultMetadata || key.startsWith("custom_")) {
                metadata[key] = this.state[key];
            }
        });
        const errors = validate(metadata);
        this.setState({
            errors: errors,
            showErrors: true
        }, callback);
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    extractMetadata(submission) {
        return submission.metadata;
    }

    /**
     * Extracts custom fields from the metadata.
     */
    extractCustom(submission) {
        const vars = Object.keys(submission.metadata).filter(x => x.startsWith("custom_")).map(key => key.match(/custom_([0-9a-z]*)_/)[1]);
        return [...new Set(vars)];
    }

    /**
     * Extracts metadata from the component state.
     */
    getStateMetadata() {
        const result = {};
        Object.assign(result, ...Object.keys(defaultMetadata).map(key => ({ [key]: this.state[key] }) ));
        Object.assign(result, ...Object.keys(this.state).filter(key => key.startsWith("custom_")).map(key => ({ [key]: this.state[key] }) ));
        return(result);
    }

    componentDidMount() {
        if (this.props.token) {
            const id = this.props.submission_id;
            if (id) {
                fetch("/api/submission/" + id, {
                    headers: new Headers({ "Authorization": this.props.token })
                })
                .then(res => res.json())
                .then(res => {
                    if (!res.metadata) {
                        res.metadata = {};
                    }
                    return res;
                })
                .then(res => {
                    this.setState({
                        ...this.extractMetadata(res),
                        custom: this.extractCustom(res),
                        submission: res
                    });
                });
            }
        } else {
            this.props.history.push("/signin");
        }
    }

    /**
     * Handles change in a form value, updates state and validates the field.
     */
    handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value,
            dirty: true
        }, () => {
            this.debounceValidateField(name, value);
        });
    }

    /**
     * Generates input component props based on the metadata specification.
     */
    makeProps(key) {
        return {
            name: key,
            label: defaultMetadata[key].label,
            required: defaultMetadata[key].required,
            numeric: defaultMetadata[key].numeric,
            email: defaultMetadata[key].email,
            phone: defaultMetadata[key].phone,
            options: defaultMetadata[key].options,
            expocode: defaultMetadata[key].expocode,
            help: defaultMetadata[key].help,
            value: this.state[key],
            handleChange: this.handleChange,
            error: this.state.errors[key] ? true : false,
            default: defaultMetadata[key].default
        }
    }

    /**
     * Navigates to the previous view.
     */
    doBack() {
        if (this.state.submission.id) {
            this.props.history.push("/submission/" + this.state.submission.id);
        } else {
            this.props.history.push("/dataset/" + this.state.submission.dataset_id);
        }
    }

    /**
     * Handles back button event.
     */
    handleBack() {
        if (this.state.dirty) {
            this.props.showConfirm(this.doBack);
        } else {
            this.doBack();
        }
    }

    /**
     * Adds a custom variable to the metadata.
     */
    handleAddVariable() {
        const identifier = Math.random().toString(36).slice(-5);
        [
            "custom_variable_abbreviation",
            "custom_variable_name",
            "custom_observation_type",
            "custom_variable_unit",
            "custom_collection_method",
            "custom_analyzing_instrument",
            "custom_analyzing_information",
            "custom_field_replicate",
            "custom_uncertainty",
            "custom_flag_abbreviation",
            "custom_flag_description",
            "custom_quality_control",
            "custom_method_reference",
            "custom_method_changes"
        ].forEach(custom => {
            const fullKey = custom.replace("custom_", "custom_" + identifier + "_");
            this.setState({
                [fullKey]: ""
            });
        });
        this.setState({
            "custom": [...this.state.custom, identifier]
        });
    }

    /**
     * Handles a save event by validating, then saving.
     */
    handleSave() {
        this.validate(this.save);
    }

    /**
     * Saves a submission.
     */
    save() {
        return fetch("/api/submission", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": this.props.token
            },
            body: JSON.stringify({
                metadata: this.getStateMetadata(),
                id: this.state.submission.id,
                dataset_id: this.state.dataset_id
            })
        })
        .then(res => res.json())
        .then(id => {
            this.setState({
                id: id,
                dirty: false
            });
            this.props.addAlert({
                "type": "success",
                "message": "Saved metadata"
            });
        });
    }

    render() {
        return (
            <div>

            { this.state.showErrors && Object.entries(this.state.errors).length > 0 && <ErrorList message="The following errors in the metadata need to be corrected before finalizing the submission:" errors={ this.state.errors }/> }

            <button className="btn btn-success mb-2" onClick={ this.handleSave }>Validate and save</button>

            <div id="accordionForm" className="mb-3">
                <FormPanel title="Data and personal information" handleSave={ this.handleSave }>
                    <form>

                        <div className="form-row align-bottom">
                            <InputField {...this.makeProps("accession_number")} ></InputField>
                            <InputField {...this.makeProps("metadataset_url")} ></InputField>
                            <InputField {...this.makeProps("dataset_url")} ></InputField>
                            <InputField {...this.makeProps("dataset_doi")} ></InputField>
                        </div>

                        <h4>Investigator 1</h4>

                        <div className="form-row">
                            <InputField {...this.makeProps("investigator_1_name")} ></InputField>
                            <InputField {...this.makeProps("investigator_1_institution")} ></InputField>
                            <InputField {...this.makeProps("investigator_1_institution_id")} ></InputField>
                        </div>
                        <div className="form-row">
                            <InputField {...this.makeProps("investigator_1_address")} ></InputField>
                            <InputField {...this.makeProps("investigator_1_phone")} ></InputField>
                            <InputField {...this.makeProps("investigator_1_email")} ></InputField>
                        </div>
                        <div className="form-row">
                            <InputField col="col-md-4" {...this.makeProps("investigator_1_researcher_id")} ></InputField>
                            <Select col="col-md-4" {...this.makeProps("investigator_1_id_type")} ></Select>
                        </div>

                        <h4>Investigator 2</h4>

                        <div className="form-row">
                            <InputField {...this.makeProps("investigator_2_name")} ></InputField>
                            <InputField {...this.makeProps("investigator_2_institution")} ></InputField>
                            <InputField {...this.makeProps("investigator_2_institution_id")} ></InputField>
                        </div>
                        <div className="form-row">
                            <InputField {...this.makeProps("investigator_2_address")} ></InputField>
                            <InputField {...this.makeProps("investigator_2_phone")} ></InputField>
                            <InputField {...this.makeProps("investigator_2_email")} ></InputField>
                        </div>
                        <div className="form-row">
                            <InputField col="col-md-4" {...this.makeProps("investigator_2_researcher_id")} ></InputField>
                            <Select col="col-md-4" {...this.makeProps("investigator_2_id_type")} ></Select>
                        </div>

                        <h4>Investigator 3</h4>

                        <div className="form-row">
                            <InputField {...this.makeProps("investigator_3_name")} ></InputField>
                            <InputField {...this.makeProps("investigator_3_institution")} ></InputField>
                            <InputField {...this.makeProps("investigator_3_institution_id")} ></InputField>
                        </div>
                        <div className="form-row">
                            <InputField {...this.makeProps("investigator_3_address")} ></InputField>
                            <InputField {...this.makeProps("investigator_3_phone")} ></InputField>
                            <InputField {...this.makeProps("investigator_3_email")} ></InputField>
                        </div>
                        <div className="form-row">
                            <InputField col="col-md-4" {...this.makeProps("investigator_3_researcher_id")} ></InputField>
                            <Select col="col-md-4" {...this.makeProps("investigator_3_id_type")} ></Select>
                        </div>

                        <h4>Data submitter</h4>

                        <div className="form-row">
                            <InputField {...this.makeProps("data_submitter_name")} ></InputField>
                            <InputField {...this.makeProps("data_submitter_institution")} ></InputField>
                            <InputField {...this.makeProps("data_submitter_institution_id")} ></InputField>
                        </div>
                        <div className="form-row">
                            <InputField {...this.makeProps("data_submitter_address")} ></InputField>
                            <InputField {...this.makeProps("data_submitter_phone")} ></InputField>
                            <InputField {...this.makeProps("data_submitter_email")} ></InputField>
                        </div>
                        <div className="form-row">
                            <InputField col="col-md-4" {...this.makeProps("data_submitter_researcher_id")} ></InputField>
                            <Select col="col-md-4" {...this.makeProps("data_submitter_id_type")} ></Select>
                        </div>

                    </form>
                </FormPanel>
                <FormPanel title="Sampling information" handleSave={ this.handleSave }>

                    <div className="form-row">
                        <InputField {...this.makeProps("title")} ></InputField>
                        <InputField area="true" {...this.makeProps("description")} ></InputField>
                        <InputField area="true" {...this.makeProps("methods")} ></InputField>
                    </div>

                    <h4>Time</h4>

                    <div className="form-row">
                        <InputField col="col-md-4" {...this.makeProps("first_day")} ></InputField>
                        <InputField col="col-md-4" {...this.makeProps("last_day")} ></InputField>
                    </div>

                    <h4>Location</h4>

                    <div className="form-row">
                        <InputField {...this.makeProps("longitude")} ></InputField>
                        <InputField {...this.makeProps("latitude")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("transect_longitude_east")} ></InputField>
                        <InputField {...this.makeProps("transect_longitude_west")} ></InputField>
                        <InputField {...this.makeProps("transect_latitude_north")} ></InputField>
                        <InputField {...this.makeProps("transect_latitude_south")} ></InputField>
                    </div>

                    <h4>Funding</h4>

                    <div className="form-row">
                        <InputField {...this.makeProps("funding_agency")} ></InputField>
                        <InputField {...this.makeProps("funding_project_title")} ></InputField>
                        <InputField {...this.makeProps("funding_project_id")} ></InputField>
                    </div>

                    <h4>Platform</h4>

                    <div className="form-row">
                        <InputField {...this.makeProps("platform_name")} ></InputField>
                        <Select {...this.makeProps("platform_category")} ></Select>
                        <InputField {...this.makeProps("platform_id")} ></InputField>
                    </div>
                    <div className="form-row">
                        <Select {...this.makeProps("platform_id_type")} ></Select>
                        <InputField {...this.makeProps("platform_owner")} ></InputField>
                        <Select {...this.makeProps("platform_country")} ></Select>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("expocode")} ></InputField>
                        <InputField {...this.makeProps("cruise_id")} ></InputField>
                        <Select {...this.makeProps("cruise_id_type")} ></Select>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("author_list")} ></InputField>
                        <InputField {...this.makeProps("references")} ></InputField>
                        <InputField {...this.makeProps("supplemental_information")} ></InputField>
                    </div>

                </FormPanel>
                <FormPanel title="Depth" handleSave={ this.handleSave }>

                    <div className="form-row">
                        <InputField {...this.makeProps("depth_variable_abbreviation")} ></InputField>
                        <Select {...this.makeProps("depth_variable_unit")} ></Select>
                    </div>

                </FormPanel>

                <FormPanel title="Dissolved Inorganic Carbon" handleSave={ this.handleSave }>

                    <div className="form-row">
                        <InputField {...this.makeProps("dic_variable_abbreviation")} ></InputField>
                        <Select {...this.makeProps("dic_observation_type")} ></Select>
                        <Select {...this.makeProps("dic_variable_unit")} ></Select>
                    </div>
                    <div className="form-row">
                        <SelectOther {...this.makeProps("dic_collection_method")} ></SelectOther>
                        <SelectOther {...this.makeProps("dic_analyzing_instrument")} ></SelectOther>
                        <InputField {...this.makeProps("dic_analyzing_information")} ></InputField>
                    </div>
                    <div className="form-row">
                        <SelectYesNoOther {...this.makeProps("dic_quality_control")} ></SelectYesNoOther>
                        <InputField {...this.makeProps("dic_flag_abbreviation")} ></InputField>
                        <SelectOther {...this.makeProps("dic_flag_description")} ></SelectOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("dic_uncertainty")} ></InputField>
                        <SelectOther {...this.makeProps("dic_field_replicate")} ></SelectOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("dic_calibration_method")} ></InputField>
                        <SelectOther {...this.makeProps("dic_calibration_frequency")} ></SelectOther>
                        <SelectOther {...this.makeProps("dic_crm_manufacturer")} ></SelectOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("dic_batch_numbers")} ></InputField>
                        <SelectOther {...this.makeProps("dic_poison")} ></SelectOther>
                        <SelectOther {...this.makeProps("dic_poison_volume")} ></SelectOther>
                        <SelectYesNoOther {...this.makeProps("dic_poison_correction")} ></SelectYesNoOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("dic_method_reference")} ></InputField>
                        <InputField area="true" {...this.makeProps("dic_method_changes")} ></InputField>
                    </div>

                </FormPanel>

                <FormPanel title="Alkalinity" handleSave={ this.handleSave }>

                    <div className="form-row">
                        <InputField {...this.makeProps("ta_variable_abbreviation")} ></InputField>
                        <Select {...this.makeProps("ta_observation_type")} ></Select>
                        <Select {...this.makeProps("ta_variable_unit")} ></Select>
                    </div>
                    <div className="form-row">
                        <SelectOther {...this.makeProps("ta_collection_method")} ></SelectOther>
                        <Select {...this.makeProps("ta_measured_calculated")} ></Select>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("ta_calculation_method")} ></InputField>
                        <SelectOther {...this.makeProps("ta_analyzing_instrument")} ></SelectOther>
                        <InputField {...this.makeProps("ta_analyzing_information")} ></InputField>
                    </div>
                    <div className="form-row">
                        <SelectYesNoOther {...this.makeProps("ta_quality_control")} ></SelectYesNoOther>
                        <InputField {...this.makeProps("ta_flag_abbreviation")} ></InputField>
                        <SelectOther {...this.makeProps("ta_flag_description")} ></SelectOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("ta_uncertainty")} ></InputField>
                        <InputField {...this.makeProps("ta_titration_type")} ></InputField>
                        <Select {...this.makeProps("ta_cell_type")} ></Select>
                        <SelectOther {...this.makeProps("ta_curve_fitting")} ></SelectOther>
                    </div>
                    <div className="form-row">
                        <SelectOther {...this.makeProps("ta_field_replicate")} ></SelectOther>
                        <InputField {...this.makeProps("ta_calibration_method")} ></InputField>
                        <SelectOther {...this.makeProps("ta_calibration_frequency")} ></SelectOther>
                        <SelectOther {...this.makeProps("ta_crm_manufacturer")} ></SelectOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("ta_batch_numbers")} ></InputField>
                        <SelectOther {...this.makeProps("ta_poison")} ></SelectOther>
                        <SelectOther {...this.makeProps("ta_poison_volume")} ></SelectOther>
                        <SelectYesNoOther {...this.makeProps("ta_poison_correction")} ></SelectYesNoOther>
                        <SelectYesNoOther {...this.makeProps("ta_blank_correction")} ></SelectYesNoOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("ta_method_reference")} ></InputField>
                        <InputField area="true" {...this.makeProps("ta_method_changes")} ></InputField>
                    </div>

                </FormPanel>

                <FormPanel title="pH" handleSave={ this.handleSave }>

                    <div className="form-row">
                        <InputField {...this.makeProps("ph_variable_abbreviation")} ></InputField>
                        <Select {...this.makeProps("ph_observation_type")} ></Select>
                        <SelectOther {...this.makeProps("ph_collection_method")} ></SelectOther>
                        <SelectOther {...this.makeProps("ph_analyzing_instrument")} ></SelectOther>
                        <InputField {...this.makeProps("ph_analyzing_information")} ></InputField>
                    </div>
                    <div className="form-row">
                        <Select {...this.makeProps("ph_scale")} ></Select>
                        <SelectYesNoOther {...this.makeProps("ph_quality_control")} ></SelectYesNoOther>
                        <InputField {...this.makeProps("ph_flag_abbreviation")} ></InputField>
                        <SelectOther {...this.makeProps("ph_flag_description")} ></SelectOther>
                        <InputField {...this.makeProps("ph_uncertainty")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("ph_measurement_temperature")} ></InputField>
                        <SelectOther {...this.makeProps("ph_field_replicate")} ></SelectOther>
                        <InputField {...this.makeProps("ph_calibration_method")} ></InputField>
                        <SelectOther {...this.makeProps("ph_calibration_frequency")} ></SelectOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("ph_dye_type")} ></InputField>
                        <SelectOther {...this.makeProps("ph_standards_values")} ></SelectOther>
                        <InputField {...this.makeProps("ph_calibration_temperature")} ></InputField>
                        <InputField {...this.makeProps("ph_temperature_correction")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("ph_reporting_temperature")} ></InputField>
                        <InputField {...this.makeProps("ph_method_reference")} ></InputField>
                        <InputField area="true" {...this.makeProps("ph_method_changes")} ></InputField>
                    </div>

                </FormPanel>

                <FormPanel title="pCO2" handleSave={ this.handleSave }>

                    <div className="form-row">
                        <InputField {...this.makeProps("pco2_variable_abbreviation")} ></InputField>
                        <Select {...this.makeProps("pco2_observation_type")} ></Select>
                        <Select {...this.makeProps("pco2_variable_unit")} ></Select>
                    </div>
                    <div className="form-row">
                        <SelectOther {...this.makeProps("pco2_collection_method")} ></SelectOther>
                        <InputField {...this.makeProps("pco2_seawater_intake_location")} ></InputField>
                        <InputField {...this.makeProps("pco2_seawater_intake_depth")} ></InputField>
                    </div>
                    <div className="form-row">
                    <SelectOther {...this.makeProps("pco2_analyzing_instrument")} ></SelectOther>
                        <InputField {...this.makeProps("pco2_analyzing_information")} ></InputField>
                        <SelectYesNoOther {...this.makeProps("pco2_quality_control")} ></SelectYesNoOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("pco2_flag_abbreviation")} ></InputField>
                        <SelectOther {...this.makeProps("pco2_flag_description")} ></SelectOther>
                        <InputField {...this.makeProps("pco2_uncertainty")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("pco2_equilibrator_type")} ></InputField>
                        <InputField {...this.makeProps("pco2_equilibrator_volume")} ></InputField>
                        <Select {...this.makeProps("pco2_equilibrator_vented")} ></Select>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("pco2_equilibrator_water_flow")} ></InputField>
                        <InputField {...this.makeProps("pco2_equilibrator_headspace_flow")} ></InputField>
                        <SelectYesNoOther {...this.makeProps("pco2_equilibrator_temperature_measured")} ></SelectYesNoOther>
                        <SelectYesNoOther {...this.makeProps("pco2_equilibrator_pressure_measured")} ></SelectYesNoOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("pco2_drying_method")} ></InputField>
                        <InputField {...this.makeProps("pco2_gas_detector_manufacturer")} ></InputField>
                        <InputField {...this.makeProps("pco2_gas_detector_model")} ></InputField>
                        <InputField {...this.makeProps("pco2_gas_detector_resolution")} ></InputField>
                        <InputField {...this.makeProps("pco2_gas_detector_uncertainty")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("pco2_calibration_method")} ></InputField>
                        <SelectOther {...this.makeProps("pco2_calibration_frequency")} ></SelectOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("pco2_standard_gas_manufacturer")} ></InputField>
                        <InputField {...this.makeProps("pco2_standard_gas_concentrations")} ></InputField>
                        <InputField {...this.makeProps("pco2_standard_gas_uncertainties")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("pco2_water_vapor_correction_method")} ></InputField>
                        <InputField {...this.makeProps("pco2_temperature_correction_method")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("pco2_temperature_reported")} ></InputField>
                        <InputField {...this.makeProps("pco2_method_reference")} ></InputField>
                        <InputField area="true" {...this.makeProps("pco2_method_changes")} ></InputField>
                    </div>

                </FormPanel>

                <FormPanel title="fCO2" handleSave={ this.handleSave }>

                    <div className="form-row">
                        <InputField {...this.makeProps("fco2_variable_abbreviation")} ></InputField>
                        <Select {...this.makeProps("fco2_observation_type")} ></Select>
                        <Select {...this.makeProps("fco2_variable_unit")} ></Select>
                    </div>
                    <div className="form-row">
                        <SelectOther {...this.makeProps("fco2_collection_method")} ></SelectOther>
                        <InputField {...this.makeProps("fco2_seawater_intake_location")} ></InputField>
                        <InputField {...this.makeProps("fco2_seawater_intake_depth")} ></InputField>
                    </div>
                    <div className="form-row">
                        <SelectOther {...this.makeProps("fco2_analyzing_instrument")} ></SelectOther>
                        <InputField {...this.makeProps("fco2_analyzing_information")} ></InputField>
                        <SelectYesNoOther {...this.makeProps("fco2_quality_control")} ></SelectYesNoOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("fco2_flag_abbreviation")} ></InputField>
                        <SelectOther {...this.makeProps("fco2_flag_description")} ></SelectOther>
                        <InputField {...this.makeProps("fco2_uncertainty")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("fco2_equilibrator_type")} ></InputField>
                        <InputField {...this.makeProps("fco2_equilibrator_volume")} ></InputField>
                        <Select {...this.makeProps("fco2_equilibrator_vented")} ></Select>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("fco2_equilibrator_water_flow")} ></InputField>
                        <InputField {...this.makeProps("fco2_equilibrator_headspace_flow")} ></InputField>
                        <SelectYesNoOther {...this.makeProps("fco2_equilibrator_temperature_measured")} ></SelectYesNoOther>
                        <SelectYesNoOther {...this.makeProps("fco2_equilibrator_pressure_measured")} ></SelectYesNoOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("fco2_drying_method")} ></InputField>
                        <InputField {...this.makeProps("fco2_gas_detector_manufacturer")} ></InputField>
                        <InputField {...this.makeProps("fco2_gas_detector_model")} ></InputField>
                        <InputField {...this.makeProps("fco2_gas_detector_resolution")} ></InputField>
                        <InputField {...this.makeProps("fco2_gas_detector_uncertainty")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("fco2_calibration_method")} ></InputField>
                        <SelectOther {...this.makeProps("fco2_calibration_frequency")} ></SelectOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("fco2_standard_gas_manufacturer")} ></InputField>
                        <InputField {...this.makeProps("fco2_standard_gas_concentrations")} ></InputField>
                        <InputField {...this.makeProps("fco2_standard_gas_uncertainties")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("fco2_water_vapor_correction_method")} ></InputField>
                        <InputField {...this.makeProps("fco2_temperature_correction_method")} ></InputField>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("fco2_temperature_reported")} ></InputField>
                        <InputField {...this.makeProps("fco2_method_reference")} ></InputField>
                        <InputField area="true" {...this.makeProps("fco2_method_changes")} ></InputField>
                    </div>

                </FormPanel>

                <FormPanel title="Temperature" handleSave={ this.handleSave }>

                    <div className="form-row">
                        <InputField {...this.makeProps("temperature_variable_abbreviation")} ></InputField>
                        <Select {...this.makeProps("temperature_observation_type")} ></Select>
                        <Select {...this.makeProps("temperature_variable_unit")} ></Select>
                    </div>
                    <div className="form-row">
                        <SelectOther {...this.makeProps("temperature_collection_method")} ></SelectOther>
                        <SelectOther {...this.makeProps("temperature_analyzing_instrument")} ></SelectOther>
                        <InputField {...this.makeProps("temperature_analyzing_information")} ></InputField>
                        <SelectYesNoOther {...this.makeProps("temperature_quality_control")} ></SelectYesNoOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("temperature_flag_abbreviation")} ></InputField>
                        <SelectOther {...this.makeProps("temperature_flag_description")} ></SelectOther>
                        <InputField {...this.makeProps("temperature_uncertainty")} ></InputField>
                    </div>
                    <div className="form-row">
                        <SelectOther {...this.makeProps("temperature_field_replicate")} ></SelectOther>
                        <InputField {...this.makeProps("temperature_method_reference")} ></InputField>
                        <InputField area="true" {...this.makeProps("temperature_method_changes")} ></InputField>
                    </div>

                </FormPanel>

                <FormPanel title="Salinity" handleSave={ this.handleSave }>

                    <div className="form-row">
                        <InputField {...this.makeProps("salinity_variable_abbreviation")} ></InputField>
                        <Select {...this.makeProps("salinity_observation_type")} ></Select>
                        <Select {...this.makeProps("salinity_variable_unit")} ></Select>
                    </div>
                    <div className="form-row">
                        <SelectOther {...this.makeProps("salinity_collection_method")} ></SelectOther>
                        <SelectOther {...this.makeProps("salinity_analyzing_instrument")} ></SelectOther>
                        <InputField {...this.makeProps("salinity_analyzing_information")} ></InputField>
                        <SelectYesNoOther {...this.makeProps("salinity_quality_control")} ></SelectYesNoOther>
                    </div>
                    <div className="form-row">
                        <InputField {...this.makeProps("salinity_flag_abbreviation")} ></InputField>
                        <SelectOther {...this.makeProps("salinity_flag_description")} ></SelectOther>
                        <InputField {...this.makeProps("salinity_uncertainty")} ></InputField>
                    </div>
                    <div className="form-row">
                        <SelectOther {...this.makeProps("salinity_field_replicate")} ></SelectOther>
                        <InputField {...this.makeProps("salinity_method_reference")} ></InputField>
                        <InputField area="true" {...this.makeProps("salinity_method_changes")} ></InputField>
                    </div>

                </FormPanel>

                {
                    this.state.custom.map(identifier => <CustomVariable key={ identifier } identifier={ identifier } handleChange={ this.handleChange } handleSave={ this.handleSave } errors={ this.state.errors } metadata={ this.state }></CustomVariable>)
                }

            </div>

            <p><button className="btn btn-light" onClick={ this.handleAddVariable }>Add variable</button></p>

        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default connect(mapStateToProps)(SubmissionForm);
