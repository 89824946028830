import React from "react";
import connect from "react-redux/es/connect/connect";
import BackButton from "./BackButton";
import FileUpload from "./FileUpload";
import Moment from "react-moment";
import SubmissionHeader from "./SubmissionHeader";
import withAlerts from "./withAlerts"
import { saveAs } from "file-saver";
import ActionButton from "./ActionButton";
import SubmissionMap from "./SubmissionMap";
import { Tabs, Tab } from "react-bootstrap";
import SubmissionForm from "./SubmissionForm";
import RecordTable from "./RecordTable";
import FileStatus from "./FileStatus";
import SomethingWrong from "./SomethingWrong";
import Loading from "./Loading";

class Submission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            submission: null,
            errors: null,
            isProcessing: new Set(),
            isUploadingMetadata: false,
            isUploadingData: false,
            records: null,
            offset: 0,
            header_lines: 1
        };
        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleFinalize = this.handleFinalize.bind(this);
        this.handleDatafileUpload = this.handleDatafileUpload.bind(this);
        this.handleMetadatafileUpload = this.handleMetadatafileUpload.bind(this);
        this.handleDownloadMetadata = this.handleDownloadMetadata.bind(this);
        this.handleDatafileDelete = this.handleDatafileDelete.bind(this);
        this.handleFileDownload = this.handleFileDownload.bind(this);
        this.handleDatafileReprocess = this.handleDatafileReprocess.bind(this);
    }
    componentDidMount() {
        if (this.props.token) {
            const { id } = this.props.match.params;
            this.setState({
                id: id
            }, this.fetchData);
        } else {
            this.props.history.push("/signin");
        }
    }
    fetchObservations() {
        fetch("/api/submission/" + this.state.id + "/observations?offset=" + this.state.offset + "&limit=1", {
            headers: new Headers({ "Authorization": this.props.token })
        })
        .then(res => res.json())
        .then(result => {
            this.setState({
                records: result.results
            });
        })
        .catch(error => {
            this.setState({
                hasError: error.message
            });
        });
    }
    fetchData() {
        fetch("/api/submission/" + this.state.id, {
            headers: new Headers({ "Authorization": this.props.token })
        })
        .then(function(res) {
            if (!res.ok) {
                throw new Error(res.statusText);
            }
            return res.json();
        })
        .then(result => {
            this.setState({
                submission: result
            });
        })
        .catch(error => {
            this.setState({
                hasError: error.message
            });
        });
        this.fetchObservations();
    }
    handleBack() {
        this.props.history.push("/dataset/" + this.state.submission.dataset_id + "/manage");
    }
    handlePrevious() {
        this.setState({
            offset: this.state.offset > 0 ? this.state.offset - 1 : 0
        }, function() {
            this.fetchObservations();
        });
    }
    handleNext() {
        this.setState({
            offset: this.state.offset + 1
        }, function() {
            this.fetchObservations();
        });
    }
    handleFinalize() {
        const { id } = this.props.match.params;
        fetch("/api/submission/" + id + "/validate", {
            headers: new Headers({ "Authorization": this.props.token })
        })
        .then(res => res.json())
        .then(errors => {
            this.setState({
                errors: errors
            });
            if (errors && Object.entries(errors).length > 0) {
                this.props.addAlert({
                    "type": "danger",
                    "message": "The submission could not be published because the supplied metadata is incomplete or invalid. Fix any issues using the metadata form."
                });
            } else {
                this.props.showConfirm(() => {
                    fetch("/api/submission/" + id + "/finalize", {
                        headers: new Headers({ "Authorization": this.props.token })
                    }).then(() => {
                        this.props.addAlert({
                            "type": "success",
                            "message": "Submission published"
                        });
                        this.fetchData();
                    });
                });
            }
        });
    }
    handleDatafileDelete(id, e) {
        e.stopPropagation();
        this.props.showConfirm(() => {
            fetch("/api/datafile/" + id, {
                method: "DELETE",
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": this.props.token
                })
            }).then(() => {
                this.props.addAlert({
                    "type": "success",
                    "message": "Datafile deleted"
                });
                this.fetchData();
            });
        });
    }
    async handleFileDownload(type, id, e) {
        e.stopPropagation();
        const url = "/api/" + type + "/" + id + "/download";
        const res = await fetch(url, {
            method: "GET",
            headers: new Headers({
                "Accept": "application/json",
                "Authorization": this.props.token
            })
        });
        const blob = await res.blob();
        let filename = type + ".xlsx";
        res.headers.forEach(function(value, name) {
            if (name.toLowerCase() === "content-disposition") {
                const matches = value.match(/filename="(.*)"/);
                if (matches && matches.length > 1) {
                    filename = matches[1];
                }
            }
        });
        saveAs(blob, filename);
    }
    async handleDownloadMetadata(id, e) {
        e.stopPropagation();
        const url = "/api/submission/" + id + "/download";
        const res = await fetch(url, {
            method: "GET",
            headers: new Headers({
                "Accept": "application/json",
                "Authorization": this.props.token
            })
        });
        const blob = await res.blob();
        let filename = "metadata_" + id + ".xlsx";
        /*res.headers.forEach(function(value, name) {
            if (name.toLowerCase() === "content-disposition") {
                const matches = value.match(/filename="(.*)"/);
                if (matches.length > 1) {
                    filename = matches[1];
                }
            }
        });*/
        saveAs(blob, filename);
    }
    handleMetadatafileDelete(id, e) {
        e.stopPropagation();
        this.props.showConfirm(() => {
            fetch("/api/metadatafile/" + id, {
                method: "DELETE",
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": this.props.token
                })
            }).then(() => {
                this.props.addAlert({
                    "type": "success",
                    "message": "Metadata file deleted"
                });
                this.fetchData();
            });
        });
    }
    handleDatafileReprocess(id, e) {
        e.stopPropagation();
        this.props.showConfirm(async () => {
            let processing = new Set(this.state.isProcessing);
            processing.add(id);
            this.setState({ isProcessing: processing });
            await this.datafileReprocess(id);
            processing = new Set(this.state.isProcessing);
            processing.delete(id);
            this.setState({ isProcessing: processing });
        });
    }
    datafileReprocess(id) {
        return fetch("/api/datafile/" + id + "/process", {
            headers: new Headers({ "Authorization": this.props.token })
        }).then(async res => {
            this.setState({
                isUploadingData: false
            });
            if (res.status === 200) {
                this.props.addAlert({
                    "type": "success",
                    "message": "File processed"
                });
            } else if (res.status === 504) {
                this.props.addAlert({
                    "type": "warning",
                    "message": "It seems that processing is taking too long, processing will continue in the background"
                });
            } else if (res.status === 400) {
                const body = await res.json();
                if (body.error === "UNEXPECTED_DATE_VALUE") {
                    this.props.addAlert({
                        "type": "danger",
                        "message": "Error processing file, unexpected date value:" + (body.hint ? (" " + body.hint) : "")
                    });
                } else if (body.error === "UNEXPECTED_TIME_VALUE") {
                    this.props.addAlert({
                        "type": "danger",
                        "message": "Error processing file, unexpected time value:" + (body.hint ? (" " + body.hint) : "")
                    });
                } else {
                    this.props.addAlert({
                        "type": "danger",
                        "message": "Error processing file, are you sure this is a valid data file?" + (body.hint ? (" " + body.hint) : "")
                    });
                }
            } else {
                this.props.addAlert({
                    "type": "danger",
                    "message": "Error processing file, are you sure this is a valid data file?"
                });
            }
            this.fetchData();
        }).catch((error) => {
            console.log(error);
        });
    }
    handleDatafileUpload(file, header_lines) {
        this.setState({
            isUploadingData: true
        });
        const { id } = this.props.match.params;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("header_lines", header_lines);
        return fetch("/api/submission/" + id + "/datafile", {
            method: "POST",
            body: formData,
            headers: new Headers({ "Authorization": this.props.token })
        }).then(res => {
            return res.json()
        }).then(data => {
            this.datafileReprocess(data.id);
        });
    }
    handleMetadatafileUpload(file) {
        this.setState({
            isUploadingMetadata: true
        });
        const { id } = this.props.match.params;
        const formData = new FormData();
        formData.append("file", file);
        return fetch("/api/submission/" + id + "/metadatafile", {
            method: "POST",
            body: formData,
            headers: new Headers({ "Authorization": this.props.token })
        }).then(async res => {
            this.setState({
                isUploadingMetadata: false
            });
            if (res.status === 200) {
                this.props.addAlert({
                    "type": "success",
                    "message": "File upload successful"
                });
            } else {
                const body = await res.json();
                this.props.addAlert({
                    "type": "danger",
                    "message": "Error processing file, are you sure this is a valid metadata file? (" + body.hint + ")"
                });
            }
            this.fetchData();
        });
    }
    render() {
        if (this.state.hasError) {
            return <SomethingWrong error={ this.state.hasError } />
        } else if (!this.state.submission) {
            return(<Loading/>)
        }
        return (
            <div>

                <BackButton onClick={ this.handleBack } />

                <SubmissionHeader submission={ this.state.submission }/>

                { !this.state.submission.finalized &&
                    <div>
                        <button className="btn btn-success ml-2 mb-3" onClick={ this.handleFinalize } >Publish version</button>
                        { /*<button className="btn btn-light ml-2 mb-3" onClick={(e) => this.handleDownloadMetadata(this.state.submission.id, e)}>Download metadata</button>*/ }
                    </div>
                }

                <div className="pagepanel">

                    <Tabs defaultActiveKey="metadata" mountOnEnter={ true }>
                        <Tab eventKey="metadata" title="Metadata file">

                            <p>Upload metadata files below or edit metadata directly using the form. Keep in mind that uploading a metadata file will overwrite any changes made manually in the form. Do not add time, coordinates, or any non numeric columns as custom variables.</p>

                            <p>A generated metadata file including metadata added through the form can be downloaded here:</p>
                            <p><button className="btn btn-primary mb-2" onClick={(e) => this.handleFileDownload("submission", this.state.submission.id, e)}>Download</button></p>

                            <table className="table table-sm table-hover">
                                <thead>
                                <tr>
                                    <th>Filename</th>
                                    <th>Created</th>
                                    <th>Processing</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                { this.state.submission.metadatafiles && this.state.submission.metadatafiles.map((metadatafile, index) =>
                                    <tr key={ index }>
                                        <td>{ metadatafile.originalname }</td>
                                        <td className="nowrap"><Moment format="D MMMM YYYY, H:mm">{ metadatafile.created }</Moment></td>
                                        <td><FileStatus file={ metadatafile } /></td>
                                        <td className="nowrap">
                                            <div>
                                                { !this.state.submission.finalized &&
                                                    <ActionButton className="mr-1" onClick={(e) => this.handleMetadatafileDelete(metadatafile.id, e)}>delete</ActionButton>
                                                }
                                                <ActionButton onClick={(e) => this.handleFileDownload("metadatafile", metadatafile.id, e)}>download original</ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                { !this.state.submission.metadatafiles && <tr><td colSpan="6"><i>No metadata files.</i></td></tr> }
                                </tbody>
                            </table>

                            {!this.state.submission.finalized &&
                                <div>
                                    <FileUpload isProcessing={ this.state.isUploadingMetadata } handleUpload={ this.handleMetadatafileUpload } accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                </div>
                            }

                        </Tab>
                        {!this.state.submission.finalized &&
                            <Tab eventKey="form" title="Metadata form">

                                <SubmissionForm submission_id={ this.state.submission.id } addAlert={ this.props.addAlert }/>

                            </Tab>
                        }
                        <Tab eventKey="data" title="Data files">

                            <p>Upload data files below. Either use one of the provided data sheet templates, or make sure your Excel sheet meets the following criteria:</p>
                            <ul>
                                <li>Date should be in a column named <code>DATE_UTC</code> and formatted as <code>Date</code> or <code>YYYY-MM-DD</code>.</li>
                                <li>Time should be in a column named <code>TIME_UTC</code> and formatted as <code>Time</code>, <code>HH:MM</code>, or <code>HH:MM:SS</code>.</li>
                                <li>Coordinates should be in columns <code>LONGITUDE</code> and <code>LATITUDE</code> as decimal degrees and formatted as <code>Number</code>.</li>
                                <li>Any variable column mapped in the metadata sheet should be formatted as <code>Number</code>.</li>
                            </ul>

                            <p>Make sure to reprocess data files after changing column names in the metadata section.</p>

                            <table className="table table-sm table-hover table-spaced">
                                <thead>
                                <tr>
                                    <th>Filename</th>
                                    <th>Created</th>
                                    <th>Processing</th>
                                    <th>Records</th>
                                    <th>Variables</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.submission.datafiles && this.state.submission.datafiles.map((datafile, index) =>
                                        <tr key={ index }>
                                            <td>{ datafile.originalname }</td>
                                            <td className="nowrap"><Moment format="D MMMM YYYY, H:mm">{ datafile.created }</Moment></td>
                                            <td><FileStatus file={ datafile } /></td>
                                            <td className={datafile.records ? "" : "lighter"}>{ parseInt(datafile.records).toLocaleString() }</td>
                                            <td className="smaller">{ datafile.variableabbreviations.join(", ") }</td>
                                            <td className="nowrap">
                                                <div>
                                                    { !this.state.submission.finalized &&
                                                    <span>
                                                        <ActionButton className="ml-1" disabled={ datafile.success == null || this.state.isProcessing.has(datafile.id) } onClick={(e) => this.handleDatafileDelete(datafile.id, e)}>delete</ActionButton>
                                                        <ActionButton className="ml-1 mr-1" disabled={ datafile.success == null || this.state.isProcessing.has(datafile.id) } onClick={(e) => this.handleDatafileReprocess(datafile.id, e)}>
                                                            reprocess
                                                            { this.state.isProcessing.has(datafile.id) && <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span> }
                                                        </ActionButton>
                                                    </span>
                                                    }
                                                    <ActionButton disabled={ datafile.success == null } onClick={(e) => this.handleFileDownload("datafile", datafile.id, e)}>download</ActionButton>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                { !this.state.submission.datafiles && <tr><td colSpan="7"><i>No data files.</i></td></tr> }
                                </tbody>
                            </table>

                            { !this.state.submission.finalized && <FileUpload isProcessing={ this.state.isUploadingData } handleUpload={ this.handleDatafileUpload } show_header_lines="true" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /> }

                        </Tab>
                        <Tab eventKey="inspect" title="Inspect data">

                            <p>The table below is a sample of the parsed dataset (row by row). Please review the column names in the metadata form in case not all variables (including depth) have been correctly detected.</p>

                            <div className="mb-1">
                                <button type="button" className="btn btn-light" disabled={ this.state.offset === 0 } onClick={ this.handlePrevious }>previous</button>
                                <span className="btn">row { this.state.offset + 1 }</span>
                                <button type="button" className="btn btn-light ml-2" disabled={ !this.state.records || this.state.records.length === 0 } onClick={ this.handleNext }>next</button>
                            </div>

                            <RecordTable records={ this.state.records }/>

                        </Tab>
                        <Tab eventKey="map" title="Station map">

                            <div className="row">
                                <div className="col-6">
                                    <SubmissionMap id={ this.state.submission.id }/>
                                </div>
                            </div>

                        </Tab>
                    </Tabs>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default withAlerts(connect(mapStateToProps)(Submission));

