import React, { PureComponent } from "react";
import Info from "./Info";
import Required from "./Required";

export default class SelectOther extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selected: "",
            text: "",
            showInput: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static getDerivedStateFromProps(props) {
        let optionIndex = -1;
        for (let i = 0; i < props.options.length; i++) {
            if (props.options[i].value === props.value) {
                optionIndex = i;
                break;
            }
        }
        if (optionIndex > -1) {
            // if value corresponds to option, clear input text
            return {
                selected: props.options[optionIndex].value,
                text: "",
                showInput: false
            };
        } else {
            // if value does not correspond to option, set input text
            return {
                selected: "",
                text: props.value,
                showInput: true
            };
        }
    }

    handleChange(e) {
        this.props.handleChange({
            "target": {
                "name": this.props.name,
                "value": e.target.value
            }
        });
    }

    render() {
        return (
            <div className={`form-group col ${this.props.col}`}>
                <label>
                    <span className="mr-2">{ this.props.label }</span>
                    { this.props.required && <Required required={ this.props.required } /> }
                    { this.props.help && <Info help={ this.props.help }></Info> }
                </label>
                <select name={ this.props.name } value={ this.state.selected } className={`form-control ${this.props.error ? "is-invalid" : "" }`} onChange={ this.handleChange }>
                    {
                        this.props.options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)
                    }
                    <option value="">Other</option>
                </select>
                {
                    this.state.showInput &&
                        <div>
                            <label className="mt-3">{ this.props.label } (specify other)</label>
                            <input type="text" className="form-control" value={ this.state.text } onChange={ this.handleChange }></input>
                        </div>
                }
            </div>
        )
    }
}