import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "open-iconic/font/css/open-iconic-bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import React, { Component } from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import Datasets from "./components/Datasets";
import Data from "./components/Data";
import FAQ from "./components/FAQ";
import ManageDataset from "./components/ManageDataset";
import Signin from "./components/Signin";
import Instructions from "./components/Instructions";
import DatasetForm from "./components/DatasetForm";
import SubmissionForm from "./components/SubmissionForm";
import Submission from "./components/Submission";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <div id="wrapper" className="d-flex flex-column">
                    <Header/>
                    <main className="container-fluid flex-fill d-flex">
                        <div className="row flex-fill">
                            <div className="col-md-12 main bg-light">
                                <Switch>
                                    <Route exact path="/" component={ Home } />
                                    <Route exact path="/datasets" render={ props => <Datasets {...props} /> } />
                                    <Route exact path="/data" render={ props => <Data {...props} /> } />
                                    <Route exact path="/faq" render={ props => <FAQ {...props} /> } />
                                    <Route exact path="/signin" render={ props => <Signin {...props} /> } />
                                    <Route exact path="/dataset/edit" component={ DatasetForm } />
                                    <Route exact path="/dataset/:id/edit" component={ DatasetForm } />
                                    <Route exact path="/dataset/:id/manage" component={ ManageDataset } />
                                    <Route exact path="/instructions" component={ Instructions } />
                                    <Route exact path="/dataset/:dataset_id/submission/edit" component={ SubmissionForm } />
                                    <Route exact path="/submission/:id" component={ Submission } />
                                </Switch>
                            </div>
                        </div>
                    </main>
                    <footer className="container-fluid d-flex align-items-center">
                        <a href="http://www.ioc-unesco.org" target="blank"><img id="ioclogo" alt="IOC logo" src="/ioc_white.png" /></a>
                    </footer>
                </div>
            </BrowserRouter>
        )
    }
}

export default App;
