import React, { Component } from "react";
import { AlertList } from "react-bs-notifier";
import { Modal } from "react-bootstrap";

export default function withAlerts(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                alerts: [],
                callback: null,
                confirmMessage: "Are you sure?"
            };
            this.addAlert = this.addAlert.bind(this);
            this.handleDismiss = this.handleDismiss.bind(this);
            this.showConfirm = this.showConfirm.bind(this);
            this.handleClose = this.handleClose.bind(this);
            this.handleProceed = this.handleProceed.bind(this);
        }
        handleDismiss(alert) {
            const alerts = this.state.alerts;
            const idx = alerts.indexOf(alert);
            if (idx >= 0) {
                this.setState({
                    alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
                });
            }
        }
        handleClose() {
            this.setState({
                show: false,
                callback: null
            });
        }
        handleProceed() {
            this.state.callback();
            this.setState({
                show: false,
                callback: null
            });
        }
        showConfirm(callback, message) {
            let newState = {
                show: true,
                callback: callback
            };
            if (message) {
                newState.confirmMessage = message;
            }
            this.setState(newState);
        }
        addAlert(alert) {
            alert.id = (new Date()).getTime();
            this.setState({
                alerts: [...this.state.alerts, alert]
            });
        }
        render() {
            return <div>
                <AlertList alerts={this.state.alerts} timeout={10000} onDismiss={(alert) => this.handleDismiss(alert)} />

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.confirmMessage}</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-light" onClick={ this.handleClose }>Cancel</button>
                        <button className="btn btn-primary" onClick={ this.handleProceed }>Proceed</button>
                    </Modal.Footer>
                </Modal>

                <WrappedComponent {...this.props} addAlert={ this.addAlert } showConfirm={ this.showConfirm } handleDismiss={ this.handleDismiss } />

            </div>
        }
    }
}