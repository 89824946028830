import React from "react";
import Collapsible from "react-collapsible";

export default function FAQ() {
    return <div className="container">
        <h2>Frequently Asked Questions</h2>
        <div className="pagepanel">

            <p>Welcome to the FAQ section of the SDG 14.3.1 Data Portal. We invite you to read the information on the ‘Instructions’ tab first. </p>

            <Collapsible trigger="Q: I cannot upload any data sets. How do I get access to this function?">
                <p>A: In order to submit a data set, all users must sign in using their OceanExpert account. Accounts can be created by following this link: <a href="https://oceanexpert.org/register/" target="_blank" rel="noreferrer noopener">https://oceanexpert.org/register/</a></p>
            </Collapsible>

            <Collapsible trigger="Q: When can I submit my data through the SDG 14.3.1 Data Portal?">
                <p>A: The SDG 14.3.1 Data Portal is always open for data submissions.</p><p>In order to remind the community of this, IOC-UNESCO sends out annual calls for data submissions to its Member States, national data centres and ocean acidification scientists. The annual reporting by IOC-UNESCO on the progress of the SDG 14.3.1 Indicator is due in February; the call will therefore be sent out several months before that date.</p>
            </Collapsible>

            <Collapsible trigger="Q: What will the submitted data be used for?">

                <p>A: Data submitted will allow data providers to present their work on ocean acidification to the scientific community, funding agencies and governments, highlighting efforts towards the achievement of the Sustainable Development Goals and the 2030 Agenda for Sustainable Development.</p>
                <p>The data will be used in three main ways:</p>
                <ol>
                    <li>For the SDG 14.3.1 indicator reporting in the annual Sustainable Development Report submitted by IOC-UNESCO to the UN Secretary General.  The Sustainable Development Report reviews progress of the 2030 Agenda for Sustainable Development and can be accessed online. The 2020 SDG Report can be read here: <a href="https://unstats.un.org/sdgs/report/2020/" target="_blank" rel="noopener nereferrer">https://unstats.un.org/sdgs/report/2020/</a></li>
                    <li>
                        <p><a href="https://public.wmo.int/en/our-mandate/climate/wmo-statement-state-of-global-climate" target="_blank" rel="noopener nereferrer">WMO Statement on the State of the Global Climate</a></p>
                        <p>Ocean acidification is one of the <a href="https://gcos.wmo.int/en/global-climate-indicators" target="_blank" rel="noopener nereferrer">Global Climate Indicators</a> featured in the annual <a href="https://library.wmo.int/doc_num.php?explnum_id=10211" target="_blank" rel="noopener nereferrer">WMO Statement on the State of the Global Climate</a>. IOC-UNESCO reports on ocean acidification data for the annual Statement.</p>
                    </li>
                    <li>Data visualisations in the form of maps
                        <ul>
                            <li>Map 1 showing data locations, linking to data origin and related metadata, including funding agencies – colour coded to show data quality categories.</li>
                            <li>Map 2 showing the annual mean pH (surface) and variability.</li>
                        </ul>
                    </li>
                </ol>
                <p>It is expected that the visualization will evolve as more data become available with time.</p>
                <p>During the data submission process via the SDG 14.3.1 Data Portal each data set will be attributed a Personal Identifier (PID), allowing for the tracing and correct citation of your results in derived products. Additionally, there is a choice of creative commons licences to define the extent to which the submitted data can be shared. As the SDG 14.3.1 Data Portal is also a platform for the storage and sharing of ocean acidification data and metadata among the scientific community, open data access is encouraged, and data and metadata files will be shared with other users.  The choices for licences on the SDG 14.3.1 Data Portal include: data sets which can be freely shared without restrictions (CC0, CC BY), with restrictions for commercial use (CC BY-NC), as well as those which only allow for IOC-UNESCO to derive products used for the purpose of the SDG indicator 14.3.1 reporting.</p>
                <p>Data sets submitted under licences that permit their sharing will become searchable and available for download on the SDG 14.3.1 Data Portal.</p>
            </Collapsible>

            <Collapsible trigger="Q: Where should ocean acidification be measured? What is a “representative sampling station”? ">
                <p>A: All ocean acidification relevant data can be submitted towards the Indicator, whether it was collected from the open ocean or at coastal sites, as long as the dataset contains at least one carbonate chemistry parameter and salinity, temperature and depth, as well as the required metadata. Submissions of data from surface waters (≤ 10 m) are encouraged, but data from all depths within the water column are accepted.</p>
                <p>The Methodology defines the representative sampling stations as sites that: 1) have a measurement frequency adequate to describe variability and trends in carbonate chemistry to deliver critical information on the exposure of and impacts on marine systems to ocean acidification, 2) provide data of sufficient quality and with comprehensive metadata information to enable integration with data from other sites in the country.</p>
            </Collapsible>

            <Collapsible trigger="Q: Can I submit my raw data without any quality control?">
                <p>A: Data sets submitted through this portal should have undergone primary quality control. </p>
                <p>The <a href="https://oceanexpert.org/document/26155" target="_blank">Methodology</a> includes primary data quality control guidelines and recommendations on which standard operating procedures (SOPs) should be used for the measurements, and data quality assessment procedures, such as using certified reference materials (CRMs). The Methodology also outlines guidance on how to establish the data quality of measurements by identifying the sources of uncertainty in the measurement and for identifying outliers in the data so as to be able to assign flags to them (please see the section titled Quality control in the Methodology). These mechanisms should be applied to all data sets before submission towards the SDG 14.3.1 indicator. </p>
                <p>In addition to the detailed metadata for each data set, including the quality control standard operating procedures (SOPs) used for each parameter, the mechanism for primary quality control listed in the Methodology are: </p>
                <ul>
                    <li>Quality control that is attached to the methodology (CRMs, tris buffer calibration, SOPs are provided),</li>
                    <li>Quality control and quality assurance of the actual data (SOPs are provided) and usage of community agreed quality flags,</li>
                    <li>Identifying and flagging of outliers,</li>
                    <li>Making determinations regarding validity of those outlying points,</li>
                    <li>Estimating uncertainty of the measurement,</li>
                    <li>Identifying all the sources of uncertainty in the measurements,</li>
                    <li>Rolling up the individual uncertainties into overall uncertainty (error propagation).</li>
                </ul>
                <p>The <a href="https://oceanexpert.org/document/26155" target="_blank">Methodology</a> contains a list with links to the standard operating procedures (SOPs) for each parameter of the carbonate system, for discrete samples, underway measurements and autonomous sensors (see the section on Standard Operating Procedures (SOP) in the Methodology). </p>
                <p>An overview of the most commonly used data quality flag schemes and their definitions can be found <a href="https://odv.awi.de/fileadmin/user_upload/odv/misc/ODV4_QualityFlagSets.pdf" target="_blank" rel="noopener nereferrer">here</a> and in the <a href="https://oceanexpert.org/document/26000" target="_blank" rel="noopener nereferrer">metadata instructions file</a>. </p>
            </Collapsible>

            <Collapsible trigger="Q: What are the different data quality categories defined in the indicator methodology?">
                <p>A: Data quality assessment and the classification of the submitted data into one of the three data quality categories outlined in the <a href="https://oceanexpert.org/document/26155" target="_blank" rel="noopener nereferrer">Methodology</a> will take place annually, prior to the submission of the annual United Nations’ SDG report. The three data quality categories are: Category 1 – Climate quality; Category 2 - Weather quality; and Category 3 - Measurements of undefined quality. These categories are described in greater detail in the SDG 14.3.1 Indicator Methodology and in the <a href="http://goa-on.org/about/plan.php" target="_blank" rel="noopener nereferrer">GOA-ON Requirements and Governance Plan (Newton et al., 2015)</a>. The data quality assessment will be carried out annually by an expert working group.  </p>
                <p>The data quality categories are described in the <a href="https://oceanexpert.org/document/26155" target="_blank" rel="noopener nereferrer">SDG 14.3.1 Indicator Methodology</a> (see the section titled Measurement quality) as:</p>
                <ul>
                    <li>
                        <b>Category 1: Climate quality</b>
                        <p>The climate quality objective is typically used to determine trends in the open ocean, shelf and coastal waters, providing data on seasonal through interannual variability on regional scales. The climate quality objective requires that a change in the dissolved carbonate ion concentration to be estimated at a particular site with a relative standard uncertainty of 1%. The carbonate ion concentration is calculated from two of the four carbonate system parameters and implies an uncertainty of approximately 0.003 in pH; of 2 μmol kg–1 in measurements of AT and CT; and a relative uncertainty of about 0.5% in the pCO<sub>2</sub>. Such precision is only currently achievable by a limited number of laboratories and is not typically achievable for all parameters by even the best autonomous sensors.</p>
                    </li>
                    <li>
                        <b>Category 2: Weather quality</b>
                        <p>The weather quality objective is suitable for many coastal and nearshore environments, particularly those with restricted circulation or where CO<sub>2</sub> system parameters are forced by processes like upwelling, pollution or freshwater inputs that can cause large variability.  The weather objective requires the carbonate ion concentration (used to calculate saturation state) to have a relative standard uncertainty of 10%. This implies an uncertainty of approximately 0.02 in pH; of 10 μmol kg–1 in measurements of AT and CT; and a relative uncertainty of about 2.5% in pCO<sub>2</sub>. Such precision should be achievable in competent laboratories and is also achievable with the best autonomous sensors.</p>
                    </li>
                    <li>
                        <b>Category 3: Measurements of undefined quality</b>
                        <p>For SDG 14.3.1, pH measurements using glass electrodes will be considered Category 3 due to the challenges of using glass pH electrodes in seawater. It is intended that the methodology provided here gives useful information for countries building capacity towards Category 1 and 2 measurements. For example, carefully calibrated glass pH electrodes may help in the identification of coastal ocean acidification hot spots and help prioritize future monitoring plans. In annual SDG 14.3.1 summary products, Category 3 measurement sites will be presented as data collection sites only, no data values will be visualized.</p>
                    </li>
                </ul>
                <p>Data quality assessment and the classification of the submitted data into one of the three data quality categories outlined in the Methodology will take place annually, prior to the submission of the yearly SDG report, by an expert working group.  </p>
            </Collapsible>

            <Collapsible trigger="Q: Which units should I use for the different parameters? ">
                <p>A: The units for the carbonate chemistry parameters are outlined in the <a href="https://oceanexpert.org/document/26155" target="_blank" rel="noopener nereferrer">SDG 14.3.1 Indicator Methodology</a> as follows: pH on total scale, pCO<sub>2</sub> [µatm or ppt], CT [µmol kg<sup>-1</sup>], AT [µmol kg<sup>-1</sup>]. The <a href="https://oceanexpert.org/document/26001" target="_blank" rel="noopener nereferrer">metadata template</a> and the online metadata form contain drop-down menus for the variable unit for each parameter showing the most commonly used units: </p>
                <ul>
                    <li>Dissolved inorganic carbon: mol kg<sup>-1</sup>, mmol kg<sup>-1</sup>, µmol kg<sup>-1</sup></li>
                    <li>Total Alkalinity: mol kg<sup>-1</sup>, mmol kg<sup>-1</sup>, µmol kg<sup>-1</sup></li>
                    <li>pH: total scale, seawater scale, NBS scale, free scale</li>
                    <li>pCO<sub>2</sub> and fCO<sub>2</sub>: μatm, ppt</li>
                    <li>Temperature: Celsius, Fahrenheit</li>
                    <li>Salinity: PSU, PPT</li>
                    <li>Depth: cm, m.</li>
                </ul>
            </Collapsible>

            <Collapsible trigger="Q: Do I have to fill in the meta data each time I submit a data set?">
                <p>A: No, the metadata entries can be duplicated and only the new information for this entry changed.  For example, if the same equipment and set up have been used again on a different day, but in the same place (e.g. for a quarterly cruise to the same sampling stations or a fixed station time series) only the date needs to be changed. </p>
            </Collapsible>

            <Collapsible trigger="Q: The abbreviations for parameters in the data template are different from the ones I use. Can I use my abbreviations for the data submission? ">
                <p>A: The abbreviations shown in the <a href="hhttps://oceanexpert.org/document/26313" target="_blank" rel="noopener nereferrer">data template</a> are examples. Any abbreviations can be used for the parameters, as long as they are used in both the data file and the associated <a href="https://oceanexpert.org/document/26001" target="_blank" rel="noopener nereferrer">metadata file</a>. The first field for each parameter in the metadata file asks for the column header name for that parameter in the data file. If the data abbreviation is correctly filled out in the metadata file, the variable abbreviation and the corresponding variable will be shown under the ‘Inspect data’ tab in the data submission process.  </p>
            </Collapsible>

            <Collapsible trigger="Q: Why are some fields in the metadata file marked in red in the metadata excel file? What does the star next to some fields in the online metadata file mean? ">
                <p> A: The fields marked in red in the metadata file in the excel sheet are the required variables. These fields need to be filled in for each of the variables contained in the data set submitted; If DIC is not submitted in this particular data set, for example, then the DIC fields can stay empty. If data is submitted for DIC and TA, then all of the red fields for these variables should be completed. </p>
                <p>In the excel form, the fields marked in <b>bold</b> have a drop-down option. The choices in the drop-down menu show the most common answer for the variable in question. For example, for <span style={{color: 'red'}}>TA: Collection method</span> the drop-down menu offers a choice between ‘water collection with a water sampler (e.g. Niskin)’, ‘water collection with bucket’ and ‘water collection with pump’. There is also an empty row in the drop-down menu, which can be filled in if the collection method used for this sample is not one of the drop-down options. </p>
                <p>The stars in the online metadata form also mark the fields for the required variables. If the required fields are not completed for the submitted variable the submission cannot be finalised and the data in the data sheet cannot be read. The online metadata form also contains drop-down menus for some of the variables. If the correct answer is not presented in the drop-down options, choose ‘other’ and fill in the correct answer in the field below (marked ‘(specify other)’). </p>
            </Collapsible>

            <Collapsible trigger="Q: Should I only submit carbonate data?">
                <p>A: No, in addition to any carbonate data parameter, temperature, salinity and sampling depth are required, at the very least. Additional parameters, such as nutrients and oxygen, can also be submitted. These additional parameters are an asset to complete the data sets and to better describe the relevant ecosystem. The data portal will soon have additional download functions, which will make it possible to filter data sets by their featured parameters, such as pH, Total Alkalinity or location. In addition to the carbonate chemistry parameters, filters for further parameters such as oxygen and nutrients will be available.  </p>
            </Collapsible>

            <Collapsible trigger="Q: What are the numbers in column D of the metadata excel template? Where do I find the ‘Help reference number’? ">
                <p>A: Column D in the excel metadata template contains the ‘Help reference number’) and refers to more detailed explanations collected in the ‘<a href="https://oceanexpert.org/document/26000" target="_blank" rel="noopener nereferrer">metadata instructions</a>’ sheet. This sheet is available to download on the landing page of the <a href="https://oa.iode.org/" target="_blank" rel="noopener nereferrer">SDG 14.3.1 Data Portal</a>. In this instructions sheet, column A lists the ‘Help reference number’ and Column B provides more detailed information on how to fill in the corresponding field of the metadata template, including required formats or units, and references for the Standard Operating Procedures (SOPs) for the analysing information. </p>
                <p>In the online metadata form, the ‘Help reference number’ function has been replaced by an information icon. The text for the instructions appears when the mouse cursor is placed on the information icon. </p>
            </Collapsible>

            <Collapsible trigger="Q: I have time series data sets from cruises. The cruises are repeated seasonally, sampling the same locations. Do I need to prepare a separate metadata form for each cruise and each location? ">
                <p>A: Each submitted data set must include a data file accompanied by the corresponding metadata file. Once the metadata file has been filled in, whether in the excel sheet or the online form on the data portal, the relevant fields can be easily amended and uploaded (excel sheet) or submitted (online form). For repeated cruises with the same vessel to the same locations, for example, only the date of the cruise will need to be changed in the metadata form. The metadata form allows for cruises to be delineated by their easternmost, westernmost, southernmost and northernmost coordinates. The corresponding data sheet should contain the coordinates for each of the samples taken during the cruise. </p>
            </Collapsible>

            <Collapsible trigger="Q: I am already submitting data to other platforms and portals (such as SOCAT, GLODAP, NCEI). Should I also submit my data to the SDG 14.3.1 Data Portal?  ">
                <p>A: At present, the direct data submissions through the SDG 14.3.1 Data Portal are necessary to highlight the progress in ocean acidification monitoring and the SDG 14.3 made by scientists in different countries to the United Nations. As the custodian agency for the SDG 14.3.1 indicator, IOC-UNESCO submits an annual report on the progress of this Indicator to the United Nations for the annual <a href="https://unstats.un.org/sdgs/report/2020/" target="_blank" rel="noopener nereferrer">Sustainable Development Goals Report</a>, featuring the data collected through the SDG 14.3.1 Data Portal. The IOC-UNESCO Ocean Science Section and IOC’s IODE, together with the leads of the relevant data platforms and portals are developing mechanisms that will enable the automatic updating of data from other platforms to the SDG 14.3.1 Data Portal. When this is implemented all relevant data sets will only need to be submitted to one platform. In the interim, please upload to the SDG14.3.1 data portal.</p>
            </Collapsible>

            <Collapsible trigger="Q: Can I access and download other submitted data sets? ">
                <p>A: The SDG 14.3.1 Data Portal is a platform for the submission, collection, validation, storage and sharing of ocean acidification data and metadata submitted towards the Sustainable Development Goal 14.3.1 Indicator. Data sets that contributors have given IOC permission to share can be viewed and downloaded via the ‘<a href="https://oa.iode.org/data" target="_blank" rel="noopener nereferrer">Data</a>’ tab on the SDG 14.3.1 Data Portal homepage. More features and filters will be added to enable all users to search and download data in the near future. Please check in regularly to see the new developments.</p>
            </Collapsible>

            <Collapsible trigger="Q: The SDG 14.3.1 Indicator calls for the “Average marine acidity (pH) measured at agreed suite of representative sampling stations.” Should I submit all of my pH and carbonate data or the average value of my pH data over the year? ">
                <p>A:  While the Indicator calls for the average pH, IOC is requesting all carbonate chemistry data. The complete data sets of carbonate data will provide additional information on variability, as well as minima and maxima of the ocean carbonate system, which may be relevant to the marine ecosystem at shorter time scales. The average will be calculated for each data set as part of the IOC-UNESCO’s reporting on the SDG 14.3.1 Indicator progress to the United Nations.</p>
            </Collapsible>

            <Collapsible trigger="Q: What is the minimum number of parameters for data submission to SDG 14.3.1? ">
                <p>A: A minimum of two carbonate chemistry parameters, plus temperature, salinity and sampling depth are needed to characterise the carbonate chemistry of the water and are therefore requested for the submission. However, all data sets containing a single carbonate chemistry parameter along with the temperature, salinity and depth and the relevant metadata, will be accepted. </p>
            </Collapsible>

            <Collapsible trigger="Q: Is only measured data acceptable for submission, or can I also include calculated values for certain carbonate chemistry parameters? ">
                <p>A: As stated in the Methodology, there are four measurable variables in the ocean carbon system. Two of the four must be measured in order to “constrain” the carbon system relative to ocean acidification: pH, carbon dioxide partial pressure (pCO<sub>2</sub>), total alkalinity (AT), and total dissolved inorganic carbon (CT). In addition, temperature and salinity data are required. </p>
                <p>Please also refer to the section Recommendations for calculation of the carbonate system in the <a href="https://oceanexpert.org/document/26155" target="_blank" rel="noopener nereferrer">SDG 14.3.1 Indicator Methodology</a>.</p>
                <p>Computer software packages are commonly used to calculate the other carbonate parameters based on the two that were measured. A range of software packages <a href="http://www.ioccp.org/index.php/documents/standards-and-methods" target="_blank" rel="noopener nereferrer">freely available</a> to download use two measured carbonate chemistry parameters and temperature, salinity and depth (hydrostatic pressure). The calculations should follow the recommendations by <a href="https://bg.copernicus.org/articles/12/1483/2015/" target="_blank" rel="noopener nereferrer">Orr et al. (2015)</a>. </p>
                <p>As stated in the <a href="https://oceanexpert.org/document/26155" target="_blank" rel="noopener nereferrer">Methodology</a>, the uncertainty of measurement should be reported. Although there are many components that potentially add to the uncertainty of measurement, new tools have recently been added to the software packages for the carbonate chemistry calculation of seawater. These software packages were evaluated by <a href="https://www.sciencedirect.com/science/article/pii/S030442031830149X?via%3Dihub" target="_blank" rel="noopener nereferrer">Orr et al. (2018)</a>, and are also <a href="http://www.ioccp.org/index.php/documents/standards-and-methods" target="_blank" rel="noopener nereferrer">freely available</a>. These software packages should be used and identified in the metadata sheet when submitting calculated carbon chemistry data.  </p>
            </Collapsible>

            <Collapsible trigger="Q: The sampling resolution of my data is not enough to resolve seasonal variability, as asked for in the Methodology.  Can I still submit my data set? ">
                <p>A: The submission of all relevant data sets, with their associated metadata, is welcome. While data that allows to resolve seasonal variability is preferred, data over shorter time scales is also useful. Ideally, the minimum should be the number of measurements needed to characterize the seasonal cycle of ocean acidity for the sampling site, according to the Methodology. Data providers that have just started measurements or have limited temporal coverage at a location are strongly encouraged to submit their data sets, even if they do not cover the full seasonal cycle, as submitting the data will highlight these efforts and may leverage the need for more sustained measurements. </p>
            </Collapsible>

            <Collapsible trigger="Q: The Methodology states that data submissions should be national submissions, what does this mean? Can I submit my data directly through the SDG 14.3.1 Data Portal? ">
                <p>A: The data submitted towards the SDG 14.3.1 Indicator count as national data submissions from IOC Member States to the Sustainable Development Goals and the 2030 Agenda for Sustainable Development. The preference is for data to be validated and approved as a national data submission from a Member State by the responsible national agencies, such as National Oceanographic Data Centres (NODCs).   However, other submission pathways are outlined below if National Data Centres are not equipped to handle these data, or such a national data centre does not exist.</p>
                <p>If a country has an appropriate data centre, the data originators and data managers should submit the data sets to the relevant NODC, rather than directly to the IOC. The pathway of the data set should go from the data originator to the data manager for data quality control and then the data set should be submitted to the NODC. Finally, the NODC should be the agency submitting the verified data set to IOC, as a national data set. These datasets should contain the complete set of information on the data originator. </p>
                <p>If data sets cannot be submitted through a NODC, either because there is no NODC in the country or the NODC is not equipped to handle the data sets, data originators or data managers can apply to the IODE for assistance. Research Institutes and Agencies, as well as Universities, can be assigned as <a href="https://www.iode.org/index.php?option=com_content&view=article&id=373&Itemid=100089" target="_blank" rel="noopener nereferrer">Associated Data Units (ADUs)</a> by the IODE. The establishment of an ADU is subject to approval by the <a href="http://legacy.ioc-unesco.org/index.php?option=com_oe&task=viewDocumentRecord&docID=17716" target="_blank" rel="noopener nereferrer">national IOC focal point</a>. Once the ADU has been approved and established, its data submissions will be considered national data submissions. More information on the process, and a <a href="https://www.iode.org/index.php?option=com_oe&task=viewGroupRecord&groupID=297" target="_blank" rel="noopener nereferrer">list of established ADUs</a>, is available on the IODE website. </p>
                <p>In case data is submitted by the data originators, the relevant national bodies will be requested by IOC-UNESCO to review these data to allow them to be regarded as official national data submissions which can be included in the annual reporting.</p>
                <p>The IODE maintains a list of all the <a href="https://www.iode.org/index.php?option=com_content&view=article&id=549&Itemid=100357" target="_blank" rel="noopener nereferrer">National Oceanographic Data Centres (NODCs)</a>, while the IOC national focal points can be found on the <a href="http://www.ioc-unesco.org/index.php?option=com_oe&task=viewDocumentRecord&docID=17716" target="_blank" rel="noopener nereferrer">IOC website</a>. </p>
                <p>If you are unable to find your National Oceanographic Data Centre (NODC) or any other relevant national agency, you are welcome to submit your data to the portal yourself. The IOC Secretariat will attempt to find the relevant national agencies and have them verify your data as a national data set. </p>
            </Collapsible>
        </div>
    </div>
}