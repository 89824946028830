import React from "react";
import {Link} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Moment from "react-moment";
import withAlerts from "./withAlerts";
import ActionButton from "./ActionButton";

class Datasets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datasets: []
        };
        this.handleDelete = this.handleDelete.bind(this);
    }
    componentDidMount() {
        // todo: check authenticated instead of token?
        if (this.props.token) {
            this.fetchData();
        } else {
            this.props.history.push("/signin");
        }
    }
    fetchData() {
        fetch("/api/dataset", {
            headers: new Headers({
                "Accept": "application/json",
                "Authorization": this.props.token
            })
        })
        .then(res => res.json())
        .then(res => {
            this.setState({
                datasets: res.results
            })
        });
    }
    handleDelete(id, e) {
        e.stopPropagation();
        this.props.showConfirm(() => {
            fetch("/api/dataset/" + id, {
                method: "DELETE",
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": this.props.token
                })
            }).then(() => {
                this.props.addAlert({
                    "type": "success",
                    "message": "Dataset deleted"
                });
                this.fetchData();
            });
        });
    }
    lastVersion(dataset) {
        const finalized = dataset.submissions ? dataset.submissions.filter(submission => submission.version != null).sort((a, b) => (a.version < b.version) ? 1 : -1) : null;
        if (finalized) {
            return finalized[0];
        }
        return null;
    }
    render() {
        return (
            <div>
                <h2>My datasets</h2>
                <div className="pagepanel">
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>User</th>
                                <th>Created</th>
                                <th>Version</th>
                                { this.props.isAdmin && <th>Actions</th> }
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.datasets.map((dataset, index) =>
                                <tr key={ index } className={ dataset.editable ? "clickable" : "" } onClick={() => { if (dataset.editable) this.props.history.push(`/dataset/${ dataset.id }/manage`) }}>
                                    <td>{ dataset.title }</td>
                                    <td className="nowrap">{ dataset.displayname }</td>
                                    <td className="nowrap"><Moment format="D MMMM YYYY">{ dataset.created }</Moment></td>
                                    <td>{ this.lastVersion(dataset) ? this.lastVersion(dataset).version : "" }</td>
                                    { this.props.isAdmin && <td>
                                        { dataset.editable &&
                                        <ActionButton onClick={(e) => this.handleDelete(dataset.id, e)}>delete</ActionButton>
                                        }
                                    </td>
                                    }
                                </tr>
                            )
                        }
                        { this.state.datasets.length === 0 && <tr><td colSpan={ this.props.isAdmin ? 7 : 6 }>No datasets found.</td></tr> }
                        </tbody>
                    </table>
                    <Link className="btn btn-warning" to={`/dataset/edit`}>New</Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token,
        isAdmin: state.isAdmin
    }
};

export default withAlerts(connect(mapStateToProps)(Datasets));
