import React from "react";
import { connect } from "react-redux";
import { loginUser } from "../store/actions";

class Signin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: ""
        };
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    onLogin = async () => {
        await loginUser(this.state)(this.props.dispatch);
        if (this.props.isAuthenticated) {
            this.props.history.push("/");
        }
    };
    render() {
        return (
            <div>
                <h2>Sign in</h2>
                <div className="pagepanel">
                    <p>
                        Sign in using your OceanExpert credentials.
                        If you do not have an OceanExpert account, register <a href="https://www.oceanexpert.org/register/" target="_blank" rel="noopener noreferrer">here</a>.
                        If you do not remember your password, request a new one <a href="https://oceanexpert.org/reset/request" target="_blank" rel="noopener noreferrer">here</a>.
                    </p>
                    { this.props.errorMessage && <div className="alert alert-danger" role="alert">{this.props.errorMessage}</div>}
                    <form className="col-6 pl-0">
                        <div className="form-group">
                            <label htmlFor="InputUsername">Username</label>
                            <input type="text" id="InputUsername" className="form-control" value={ this.state.username } name="username" onChange={ this.handleChange }></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="InputPassword">Password</label>
                            <input type="password" id="InputPassword" className="form-control" value={ this.state.password } name="password" onChange={ this.handleChange }></input>
                        </div>
                    </form>
                    <button className="btn btn-primary" onClick={ this.onLogin }>
                        Sign in
                        { this.props.isFetching && <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span> }
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.isAuthenticated,
        isFetching: state.isFetching,
        errorMessage: state.errorMessage
    };
};

export default connect(mapStateToProps)(Signin);