import React from "react";
import Info from "./Info";
import Required from "./Required";

export default React.memo(function InputField(props) {
    if (props.options) {
        console.warn("Options provided to regular input field " + props.name);
    }
    return <div className={`form-group col`}>
        <label>
            <span className="mr-2">{ props.label }</span>
            { props.required && <Required required={ props.required } /> }
            { props.help && <Info help={ props.help }></Info> }
        </label>
        {
            props.area ?
            <textarea type="text" className={`form-control ${props.error ? "is-invalid" : "" }`} value={ props.value } name={ props.name } onChange={ props.handleChange }></textarea>
            :
            <input type="text" className={`form-control ${props.error ? "is-invalid" : "" }`} value={ props.value } name={ props.name } onChange={ props.handleChange }></input>
        }
    </div>
});