import React from "react";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";
import SubmissionStatus from "./SubmissionStatus";
import connect from "react-redux/es/connect/connect";
import Moment from "react-moment";
import withAlerts from "./withAlerts";
import SomethingWrong from "./SomethingWrong";
import ActionButton from "./ActionButton";
import LicenseIcon from "./LicenseIcon";
import Loading from "./Loading";

class ManageDataset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            dataset: null
        };
        this.handleSubmissionDelete = this.handleSubmissionDelete.bind(this);
        this.handleCreateSubmission = this.handleCreateSubmission.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }
    componentDidMount() {
        if (this.props.token) {
            const { id } = this.props.match.params;
            this.setState({
                id: id
            }, this.fetchData);
        } else {
            this.props.history.push("/signin");
        }
    }
    fetchData() {
        fetch("/api/dataset/" + this.state.id, {
            headers: new Headers({ "Authorization": this.props.token })
        })
        .then(function(res) {
            if (!res.ok) {
                throw new Error(res.statusText);
            }
            return res.json();
        })
        .then(res => {
            this.setState({
                dataset: res
            });
        })
        .catch(error => {
            this.setState({
                hasError: error.message
            });
        });
    }
    handleCreateSubmission() {
        return fetch("/api/submission", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": this.props.token
            },
            body: JSON.stringify({
                metadata: null,
                dataset_id: this.state.dataset.id
            })
        })
        .then(res => res.json())
        .then(id => {
            this.props.history.push("/submission/" + id);
        });
    }
    handleSubmissionDelete(id, e) {
        e.stopPropagation();
        this.props.showConfirm(() => {
            fetch("/api/submission/" + id, {
                method: "DELETE",
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": this.props.token
                })
            }).then(() => {
                this.props.addAlert({
                    "type": "success",
                    "message": "Version deleted"
                });
                this.fetchData();
            });
        });
    }
    handleBack() {
        this.props.history.push("/datasets");
    }
    render() {
        if (this.state.hasError) {
            return <SomethingWrong error={ this.state.hasError } />
        } else if (!this.state.dataset) {
            return(<Loading/>)
        }
        return (
            <div>
                <BackButton onClick={ this.handleBack } />
                { this.state.dataset.title ? <h2>{ this.state.dataset.title }</h2> : <h2>Dataset</h2> }

                <table className="table-sm table-nonfluid table-borderless">
                    <tbody>
                    <tr>
                        <td>
                            <p>
                                <span className="fieldname">Title</span>
                                <br/>{ this.state.dataset.title }
                            </p>
                        </td>
                        <td className="pl-4 nowrap">
                            <p>
                                <span className="fieldname">User</span>
                                <br/>{ this.state.dataset.displayname }
                            </p>
                        </td>
                        <td className="pl-4 nowrap">
                            <p>
                                <span className="fieldname">Created</span>
                                <br/><Moment format="D MMMM YYYY, H:mm">{ this.state.dataset.created }</Moment>
                            </p>
                        </td>
                        <td className="pl-4">
                            <span className="fieldname">License</span>
                            <br/><LicenseIcon className="mt-1" license={ this.state.dataset.license } />
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Link className="btn btn-success ml-2 mb-3" to={`/dataset/${ this.state.dataset.id }/edit`}>Edit dataset</Link>

                <div className="pagepanel">

                    <h3>Versions</h3>
                    { this.state.dataset.submissions ? <table className="table table-sm table-hover table-spaced">
                        <thead>
                            <tr>
                                <th>Created</th>
                                <th>Modified</th>
                                <th>Published</th>
                                <th>License</th>
                                <th>Records</th>
                                <th>Variables</th>
                                <th>Variable types</th>
                                <th>Status</th>
                                <th>Version</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.dataset.submissions.map((submission, index) =>
                                <tr key={ index } className="clickable" onClick={() => this.props.history.push(`/submission/${ submission.id }`)}>
                                    <td className="nowrap"><Moment format="D MMMM YYYY, H:mm">{ submission.created }</Moment></td>
                                    <td className="nowrap">{ submission.modified && <Moment format="D MMMM YYYY, H:mm">{ submission.modified }</Moment> }</td>
                                    <td className="nowrap">{ submission.finalized && <Moment format="D MMMM YYYY, H:mm">{ submission.finalized }</Moment> }</td>
                                    <td><LicenseIcon className="mt-1" license={ submission.license } /></td>
                                    <td className={submission.records ? "" : "lighter"}>{ parseInt(submission.records).toLocaleString() }</td>
                                    <td className={submission.variables ? "" : "lighter"}>{ submission.variables }</td>
                                    <td className="smaller">{ submission.variableabbreviations.join(", ") }</td>
                                    <td><SubmissionStatus submission={ submission } /></td>
                                    <td>{ submission.version }</td>
                                    <td>{ !submission.finalized && <ActionButton onClick={ (e) => this.handleSubmissionDelete(submission.id, e) }>delete</ActionButton> }</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table> : <p>No versions.</p> }
                    <button className="btn btn-warning" onClick={ this.handleCreateSubmission }>New version</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default withAlerts(connect(mapStateToProps)(ManageDataset));

