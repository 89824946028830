import React from "react";

export default function ErrorList(props) {
    return <div className="alert alert-danger">
        <p>{ props.message }</p>
        <ul>
            {
                Object.keys(props.errors).map(key => {
                    return props.errors[key] ? props.errors[key].map((error, i) => {
                        return <li key={key + i}>{ key }: { error }</li>
                    }) : null
                })
            }
        </ul>
    </div>
}