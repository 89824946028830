import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logoutUser } from "../store/actions";
import connect from "react-redux/es/connect/connect";
import { NavDropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class Header extends Component {
    constructor(props) {
        super(props);
        this.handleSignout = this.handleSignout.bind(this);
    }
    handleSignout() {
        logoutUser()(this.props.dispatch);
        this.props.history.push("/");
    }
    render() {
        const navTitle = <span>{ this.props.isAdmin && <span className="oi oi-lock-unlocked mr-2"></span> }{ this.props.username }</span>;
        return(
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand">SDG 14.3.1 data portal</Link>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item mr-3">
                            <Link className="nav-link active" to="/instructions"><span className="oi oi-book mr-1"></span>Instructions</Link>
                        </li>
                        <li className="nav-item mr-3">
                            <Link className="nav-link active" to="/faq"><span className="oi oi-book mr-1"></span>FAQ</Link>
                        </li>
                        <li className="nav-item mr-3">
                            <Link className="nav-link active" to="/datasets"><span className="oi oi-paperclip mr-1"></span> My datasets</Link>
                        </li>
                        <li className="nav-item mr-3">
                            <Link className="nav-link active" to="/data"><span className="oi oi-pulse mr-1"></span> Data</Link>
                        </li>

                        { this.props.isAuthenticated &&
                            <NavDropdown title={ navTitle } id="nav-dropdown">
                                <NavDropdown.Item onClick={ () => this.handleSignout() }>Sign out</NavDropdown.Item>
                            </NavDropdown>
                        }
                        { !this.props.isAuthenticated &&
                            <li className="nav-item">
                                <Link className="nav-link" to="/signin">Sign in</Link>
                            </li>
                        }
                    </ul>
                </div>
            </nav>
        )
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.isAuthenticated,
        isAdmin: state.isAdmin,
        username: state.username
    };
}

export default connect(mapStateToProps)(withRouter(Header));