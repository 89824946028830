import React from "react";
import StationMap from "./StationMap";

export default function Home() {
    return <div className="container">
        <h2>Welcome to the SDG 14.3.1 data portal</h2>
        <div className="pagepanel">

            <StationMap/>

            <p className="mt-3">This SDG 14.3.1 Data Portal is a tool for the submission, collection, validation, storage and sharing of ocean acidification data and metadata submitted towards the Sustainable Development Goal 14.3.1 Indicator: Average marine acidity (pH) measured at agreed suite of representative sampling stations.</p>
            <p>In 2015, the United Nations adopted the 2030 Agenda and a set of Sustainable Development Goals (SDG), including a goal dedicated to the ocean, SDG 14, which calls to "conserve and sustainably use the oceans, seas and marine resources for sustainable development". <a href="http://www.unesco.org/new/en/natural-sciences/ioc-oceans/" target="_blank" rel="noopener noreferrer">The Intergovernmental Oceanographic Commission</a> (IOC) of UNESCO was identified as the custodian agency for the <a href="https://sustainabledevelopment.un.org/sdg14" target="_blank" rel="noopener noreferrer">SDG Target 14.3</a>: "Minimize and address the impacts of ocean acidification, including through enhanced scientific cooperation at all levels", and the associated SDG Indicator 14.3.1 ("Average marine acidity (pH) measured at agreed suite of representative sampling stations").</p>
            <p>Thanks to the cooperation and support received by the Commission in the past years from its <a href="https://www.iode.org/" target="_blank" rel="noopener noreferrer">International Oceanographic Data and Information Exchange Programme</a> (IODE), international ocean acidification experts (including data managers) and the <a href="http://goa-on.org/home.php" target="_blank" rel="noopener noreferrer">Global Ocean Acidification Observing Network</a> (GOA-ON) the indicator methodology was developed and is now freely available. </p>
            <p>The SDG Indicator 14.3.1 Methodology provides the necessary guidance on how to conduct ocean acidification observation, what to measure and how, providing best practice and methods approved by the scientific ocean acidification community. It further offers support on how to and what kind of data sets to submit to IOC, to ensure the production of quality controlled global and possibly regional products. </p>
            <p>The full text of the SDG 14.3.1 Indicator Methodology, the data template, the metadata template and the metadata instructions file can all be found and downloaded here:</p>
            <ul>
                <li><a href="https://oceanexpert.org/document/26155" target="_blank" rel="noopener noreferrer">Indicator methodology</a></li>
                <li><a href="https://oceanexpert.org/document/26001" target="_blank" rel="noopener noreferrer">Metadata template</a></li>
                <li><a href="https://oceanexpert.org/document/26313" target="_blank" rel="noopener noreferrer">Data template</a></li>
                <li><a href="https://oceanexpert.org/document/26000" target="_blank" rel="noopener noreferrer">Metadata instructions</a></li>
            </ul>
            <p>We are looking forward to your contributions! Please be assured that all your submissions will be attributed a Digital Object Identifier (DOI), allowing for the tracing and correct citation of your results in derived products. We encourage open data access and expect that your data and metadata contributions will be shared and used. We welcome data sets which can be freely shared without restrictions (CC0, CC BY), with restrictions for commercial use (CC BY-NC), as well as those which only allow for IOC-UNESCO to derive products used for the purpose of the SDG indicator 14.3.1 reporting. The data submission process includes all of the steps outlined above.</p>
            <p>Thank you very much!</p>
            <p>IOC-UNESCO</p>


        </div>
    </div>
}