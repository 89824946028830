import React from "react";
import { Link } from "react-router-dom";

export default function Instructions() {
    return <div className="container">
        <h2>How to use this portal</h2>
        <div className="pagepanel">
            <p>To upload your dataset to the SDG 14.3.1 portal, please follow the following steps:</p>
            <ol>
                <li>Sign in using your OceanExpert credentials. If you do not have an OceanExpert account, register <a href="https://www.oceanexpert.org/register/" target="_blank" rel="noopener noreferrer">here</a>.</li>
                <li>Create a new dataset on the <Link to="/datasets">My datasets</Link> page.</li>
                <li>Create a draft version.</li>
                <li>Upload your <em>metadata spreadsheet</em>, or edit your dataset metadata using the web form. After uploading a metadata spreadsheet, the form can be used to make changes to the uploaded metadata. The metadata record will be validated on saving, and all issues need to be resolved before the version can be published.</li>
                <li>Upload your <em>data spreadsheet</em>. The data in the spreadsheet will be interpreted using the previously entered metadata. If changes are made to the metadata record after uploading a data spreadsheet, a data spreadsheet can be reprocessed by clicking the reprocess button in the data file table. Verify the uploaded data by inspecting the record number and detected variables in the data file table. Multiple data spreadsheets can be added to a single version.</li>
                <li>Publish your version. If necessary, fix any metadata issues in the metadata form.</li>
                <li>After publishing a version, it can no longer be changed. If you have an updated version of your dataset, please create a new submission. Older submissions will be archived.</li>
            </ol>
            <p>For support please contact <a href="mailto:k.isensee@unesco.org">k.isensee@unesco.org</a>.</p>
        </div>
    </div>
}