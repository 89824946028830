import React from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import colorbrewer from "colorbrewer";

export default class StationMap extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.map = null;
    }
    componentDidMount() {
        const node = this.mapRef.current;
        const map = new mapboxgl.Map({
            container: node,
            zoom: 0,
            center: [20, 15]
        });
        map.addSource("stations", {
            "type": "vector",
            "tiles": [
                window.location.origin.toString() + "/api/tile/{z}/{x}/{y}.mvt"
            ]
        });
        map.addSource("background", {
            "type": "raster",
            "tiles": [
                "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            ],
            "tileSize": 256
        });
        map.addLayer({
            "id": "yourTileLayer",
            "type": "raster",
            "source": "background",
            "paint": {
                "raster-fade-duration": 100
            }
        });
        const colors = colorbrewer.BuPu[5];
        map.addLayer({
            "id": "stations",
            "type": "fill",
            "source": "stations",
            "source-layer": "stations",
            "layout": {
            },
            "paint": {
                "fill-outline-color": "#ffffff",
                "fill-opacity": 0.8,
                "fill-color": [
                    "interpolate",
                    ["linear"],
                    ["get", "n"],
                    1, colors[0],
                    10, colors[1],
                    100, colors[2],
                    1000, colors[3],
                    10000, colors[4]
                ]
            }
        });
        map.addControl(new mapboxgl.NavigationControl());
    }
    render() {
        return <div ref={ this.mapRef } className="stationmap" />
    }
}