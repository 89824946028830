import React from "react";
import { Collapse } from "react-bootstrap";

export default class FormPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: (this.props.open === "true") || false
        };
    }
    render() {
        const { open } = this.state;
        return (
            <div>
                <div className="section-header mt-2" onClick={() => this.setState({ open: !open })} aria-expanded={open}>{ this.props.title }</div>
                <Collapse in={this.state.open}>
                    <div>
                        <div className="card-body">
                            { this.props.children }
                            <button className="btn btn-success mb-1 mt-2" onClick={ this.props.handleSave }>Validate and save</button>
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    }
}