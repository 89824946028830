import React from "react";
import FormPanel from "./FormPanel";
import InputField from "./InputField";
import { metadata as defaultMetadata } from "../lib/model";
import Select from "./Select";
import SelectOther from "./SelectOther";

export default function CustomVariable(props) {
    function makeProps(key) {
        const fullKey = key.replace("custom_", "custom_" + props.identifier + "_");
        return {
            name: fullKey,
            label: defaultMetadata[key].label,
            required: defaultMetadata[key].required,
            numeric: defaultMetadata[key].numeric,
            email: defaultMetadata[key].email,
            phone: defaultMetadata[key].phone,
            options: defaultMetadata[key].options,
            expocode: defaultMetadata[key].expocode,
            help: defaultMetadata[key].help,
            value: props.metadata[fullKey],
            handleChange: props.handleChange,
            error: props.errors[fullKey] ? true : false
        };
    }

    return <FormPanel title="Custom variable" handleSave={ props.handleSave }>
            <div className="form-row">
                <InputField {...makeProps("custom_variable_abbreviation")} ></InputField>
                <InputField {...makeProps("custom_variable_name")} ></InputField>
                <Select {...makeProps("custom_observation_type")} ></Select>
                <InputField {...makeProps("custom_variable_unit")} ></InputField>
            </div>
            <div className="form-row">
                <SelectOther {...makeProps("custom_collection_method")} ></SelectOther>
                <InputField {...makeProps("custom_analyzing_instrument")} ></InputField>
                <InputField {...makeProps("custom_analyzing_information")} ></InputField>
                <InputField {...makeProps("custom_quality_control")} ></InputField>
            </div>
            <div className="form-row">
                <InputField {...makeProps("custom_flag_abbreviation")} ></InputField>
                <SelectOther {...makeProps("custom_flag_description")} ></SelectOther>
                <InputField {...makeProps("custom_uncertainty")} ></InputField>
            </div>
            <div className="form-row">
                <SelectOther {...makeProps("custom_field_replicate")} ></SelectOther>
                <InputField {...makeProps("custom_method_reference")} ></InputField>
                <InputField {...makeProps("custom_method_changes")} ></InputField>
            </div>
        </FormPanel>
}