const initialState = {
    isFetching: false,
    isAuthenticated: localStorage.getItem("token") ? true : false,
    isAdmin: localStorage.getItem("roles") ? localStorage.getItem("roles").includes("admin") : false,
    token: localStorage.getItem("token"),
    username: localStorage.getItem("username")
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case "LOGIN_REQUEST":
            return Object.assign({}, state, {
                isFetching: true,
                isAuthenticated: false,
                errorMessage: ""
            });
        case "LOGIN_SUCCESS":
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: true,
                isAdmin: action.isAdmin,
                errorMessage: "",
                token: action.token,
                username: action.username
            });
        case "LOGIN_FAILURE":
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.errorMessage
            });
        case "LOGOUT_SUCCESS":
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                token: null,
                username: null
            });
        default:
            return state;
    }
};

export default rootReducer;