const jwt = require("jsonwebtoken");

function requestLogin(credentials) {
    return {
        type: "LOGIN_REQUEST",
        isFetching: true,
        isAuthenticated: false,
        isAdmin: false,
        credentials
    }
}

function receiveLogin(user) {
    return {
        type: "LOGIN_SUCCESS",
        isFetching: false,
        isAuthenticated: true,
        token: user.token,
        username: user.username,
        roles: user.roles,
        isAdmin: user.roles ? user.roles.includes("admin") : false
    }
}

function loginError(message) {
    return {
        type: "LOGIN_FAILURE",
        isFetching: false,
        isAuthenticated: false,
        isAdmin: false,
        errorMessage: message
    }
}

function requestLogout() {
    return {
        type: "LOGOUT_REQUEST",
        isFetching: true,
        isAuthenticated: true
    }
}

function receiveLogout() {
    return {
        type: "LOGOUT_SUCCESS",
        isFetching: false,
        isAuthenticated: false,
        isAdmin: false
    }
}

export function logoutUser() {
    return dispatch => {
        dispatch(requestLogout());
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        dispatch(receiveLogout());
    }
}

export function loginUser(credentials) {
    return dispatch => {
        dispatch(requestLogin(credentials))
        return fetch("/api/authenticate", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                username: credentials.username,
                password: credentials.password
            })
        })
        .then(res => {
            if (!res.ok) {
                const e = "Incorrect username or password";
                dispatch(loginError(e));
                throw new Error(e);
            }
            return res
        })
        .then(res => res.json())
        .then(res =>  {
            const decoded = jwt.decode(res.token);
            localStorage.setItem("token", res.token);
            localStorage.setItem("username", decoded.username);
            localStorage.setItem("roles", decoded.roles);
            dispatch(receiveLogin({
                token: res.token,
                username: decoded.username,
                roles: decoded.roles
            }));
        })
        .catch(e => console.log(e));
    }
}
