import React from "react";

export default function RecordTable(props) {
    return <div>
        <table className="table table-sm table-hover table-spaced">
            <thead>
            <tr>
                <th>Time</th>
                <th>Longitude</th>
                <th>Latitude</th>
                <th>Variable abbrevation</th>
                <th>Variable</th>
                <th>Value</th>
                <th>Unit</th>
                <th>Flag abbrevation</th>
                <th>Flag value</th>
            </tr>
            </thead>
            <tbody>
            {
                props.records && props.records.length ? props.records.map((record, i) => {
                    return record.observations && record.observations.map((observation, j) => {
                        return <tr key={ i + "_" + j }>
                            <td>{ record.datetime }</td>
                            <td>{ record.longitude }</td>
                            <td>{ record.latitude }</td>
                            <td>{ observation.abbreviation }</td>
                            <td>{ observation.name }</td>
                            <td>{ observation.value }</td>
                            <td>{ observation.unit }</td>
                            <td>{ observation.flag_abbreviation }</td>
                            <td>{ observation.flag }</td>
                        </tr>
                    });
                }) : <tr><td colSpan="8"><i>No observations.</i></td></tr>
            }
            </tbody>
        </table>
    </div>
};