import React from "react";

export default function LicenseIcon(props) {
    if (props.license) {
        if (props.license === "cc0") {
            return <a href="https://creativecommons.org/publicdomain/zero/1.0/" target="_blank" rel="noopener noreferrer">CC0</a>
        } else if (props.license === "cc by") {
            return <a href="https://creativecommons.org/licenses/by/2.0/" target="_blank" rel="noopener noreferrer">CC BY</a>
        } else if (props.license === "cc by-nc") {
            return <a href="https://creativecommons.org/licenses/by-nc/2.0/" target="_blank" rel="noopener noreferrer">CC BY-NC</a>
        } else if (props.license === "restricted") {
            return <p>Restricted</p>
        }
    } else {
        return "";
    }
}