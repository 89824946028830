import React from "react";
import Info from "./Info";
import Required from "./Required";

export default React.memo(function Select(props) {
    return <div className={`form-group col`}>
        <label>
            <span className="mr-2">{ props.label }</span>
            { props.required && <Required required={ props.required } /> }
            { props.help && <Info help={ props.help }></Info> }
        </label>
        <select name={ props.name } value={ props.value } className={`form-control ${ props.error ? "is-invalid" : "" }` } onChange={ props.handleChange }>
            <option>{ props.default }</option>
            {
                props.options.map(option => <option key={ option.value } value={ option.value }>{ option.label }</option>)
            }
        </select>
    </div>
});