import React from "react"
import connect from "react-redux/es/connect/connect";
import BackButton from "./BackButton";
import withAlerts from "./withAlerts";

class DatasetForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataset: {
                "title": "",
                "license": "cc0"
            }
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }
    componentDidMount() {
        if (this.props.token) {
            const { id } = this.props.match.params;
            if (id) {
                fetch("/api/dataset/" + id, {
                    headers: new Headers({ "Authorization": this.props.token })
                })
                .then(res => res.json())
                .then((result) => {
                    this.setState({
                        dataset: result
                    });
                });
            }
        } else {
            this.props.history.push("/signin");
        }
    }
    handleChange(e) {
        const { dataset } = { ...this.state };
        dataset[e.target.name] = e.target.value;
        this.setState({ dataset: dataset });
    }
    handleBack() {
        const { id } = this.props.match.params;
        if (id) {
            this.props.history.push("/dataset/" + id + "/manage");
        } else {
            this.props.history.push("/datasets");
        }
    }
    handleSave() {
        fetch("/api/dataset", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": this.props.token
            },
            body: JSON.stringify(this.state.dataset)
        })
        .then(res => res.json())
        .then(id => {
            this.props.addAlert({
                "type": "success",
                "message": "Saved dataset"
            });
            this.props.history.push("/dataset/" + id + "/manage");
        });
    }
    render() {
        return (
            <div>
                <BackButton onClick={ this.handleBack }/>
                <h3>Edit dataset</h3>
                <div className="pagepanel">

                    <p><span className="oi oi-warning"></span> Please only enter data and metadata information associated to measured variables.</p>

                    <form>
                        <div className="form-group">
                            <label htmlFor="InputTitle">Title</label>
                            <input type="text" id="InputTitle" className="form-control" value={ this.state.dataset.title } name="title" onChange={ this.handleChange }></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="InputLicense">License</label>
                            <p>About the <a href="https://creativecommons.org/licenses" target="_blank" rel="noopener noreferrer">Creative Commons licenses</a>:</p>
                            <ul>
                                <li>CC0 - Dedicates the work to the public domain and does not legally require attribution to the data source.</li>
                                <li>CC BY - This license lets others distribute, remix, adapt, and build upon your work, even commercially, as long as they credit you for the original creation.</li>
                                <li>CC BY-NC - This license lets others remix, adapt, and build upon your work non-commercially, and although their new works must also acknowledge you and be non-commercial, they don’t have to license their derivative works on the same terms.</li>
                            </ul>
                            <select id="InputTitle" className="form-control" value={ this.state.dataset.license } name="license" onChange={ this.handleChange }>
                                <option value="cc0">CC0</option>
                                <option value="cc by">CC-BY</option>
                                <option value="cc by-nc">CC-BY-NC</option>
                                <option value="restricted">Data and metadata can be only used by IOC-UNESCO for the purpose of SDG 14.3.1 reporting and related derived products</option>
                            </select>
                        </div>
                    </form>
                    <button className="btn btn-success" onClick={ this.handleSave }>Save</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default withAlerts(connect(mapStateToProps)(DatasetForm));
