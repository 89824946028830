import React from "react";
import SubmissionStatus from "./SubmissionStatus";
import Moment from "react-moment";

export default function SubmissionHeader(props) {
    return <div>
        <h2>
            <span className="mr-2">{ props.submission.title ? props.submission.title : "Version" }</span>
            <SubmissionStatus submission={ props.submission } />
        </h2>

        <div className="row">
            <div className="col-6">
                <table className="table-sm table-nonfluid .table-borderless">
                    <tbody>
                    <tr>
                        <td className="nowrap">
                            <p>
                                <span className="fieldname">Created</span>
                                <br/><Moment format="D MMMM YYYY, H:mm">{ props.submission.created }</Moment>
                            </p>
                        </td>
                        <td className="pl-4 nowrap">
                            <p>
                                <span className="fieldname">Modified</span>
                                <br/>{ props.submission.modified && <Moment format="D MMMM YYYY, H:mm">{props.submission.modified}</Moment> }
                            </p>
                        </td>
                        <td className="pl-4 nowrap">
                            { props.submission.finalized &&
                            <p>
                                <span className="fieldname">Published</span>
                                <br/><Moment format="D MMMM YYYY, H:mm">{props.submission.finalized}</Moment>&nbsp;
                            </p>
                            }
                        </td>
                        <td className="pl-4 nowrap">
                            { props.submission.finalized &&
                            <p>
                                <span className="fieldname">Version</span>
                                <br/>{props.submission.version}&nbsp;
                            </p>
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>

    </div>
}